import styled from '@emotion/styled'

const commonMessages = `
display: flex;
justify-content: center;
margin: 0 15px;
min-height: 15px;
color:white;
padding: 2px 10px;
border-radius: 3px;
-webkit-border-radius: 3px;
-moz-border-radius: 3px;
-ms-border-radius: 3px;
-o-border-radius: 3px;
`

/**
 * Estilos de mensages
 */
export const ErrorMessage = styled.div`
    ${commonMessages}
    :not(:empty) {
        background-color: var(--error-color);
    }
`
export const InfoMessage = styled.div`
    ${commonMessages}
    :not(:empty) {
        background-color: var(--info-color);
    }
`
export const SuccessMessage = styled.div`
    ${commonMessages}
    :not(:empty) {
        background-color: var(--success-color);
    }
`