	
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk'

import tires from './reducers/tires';
import voucher from './reducers/voucher';

const middleware = [ thunk ]
if (process.env.NODE_ENV !== 'production') {
  //middleware.push(createLogger())
}

const store = createStore(
  combineReducers({
    tires, voucher
  }),
  applyMiddleware(...middleware)
)

export default store;