import {useContext} from 'react'

import Context from 'context/UserContext'

import { isEquivalent, hightLess } from 'hooks/useUtils'

import {getNormalTires} from 'services/tireSearch'

export const REQUEST_TIRES = 'REQUEST_TIRES'
export const SET_TIRES = 'SET_TIRES'
export const REMOVE_TIRES = 'REMOVE_TIRES'
export const PRUEBA_CART = 'PRUEBA_CART'
export const CHANGE_QUANTITY = 'CHANGE_QUANTITY'
export const CHANGE_MONTAJE = 'CHANGE_MONTAJE'
export const CHANGE_ALINEACION = 'CHANGE_ALINEACION'
export const CHANGE_INFLADO = 'CHANGE_INFLADO'

export const requestTires = search => ({
    type: 'REQUEST_TIRES',
    search
})

export const reciveTires = (search, items, err = null) => ({
  type: 'SET_TIRES',
  search,
  tires: items,
  errorMessage: err,
  receivedAt: Date.now()
})


export const changeQuantity = (quantity) => ({
  type: 'CHANGE_QUANTITY',
  quantity: quantity
})

export const changeMontaje = (montaje) => ({
  type: 'CHANGE_MONTAJE',
  montaje: montaje
})

export const changeAlineacion = (alineacion) => ({
  type: 'CHANGE_ALINEACION',
  alineacion: alineacion
})

export const changeInflado = (inflado) => ({
  type: 'CHANGE_INFLADO',
  inflado: inflado
})

export const removeTires = () => ({
  type: 'REMOVE_TIRES'
})

const fetchTires = search => dispatch => {
    dispatch(requestTires(search))
    const {userData} = useContext(Context)

    return getNormalTires({ userId: userData.id, userDireccionId: userData.direccion.id, measure: search.search.measure, filters: search.search.filters })
      .then(items => {
        items.sort(hightLess)
        dispatch(reciveTires(search, items))
      })
      .catch(err => {dispatch(reciveTires(search, [], err))})
}

const shouldFetchTires = (state, search, dispatch) => {
    if (search.search.measure === undefined) {
      dispatch(reciveTires(search, []))
      return false
    }

    if (!state.tires.prevSearch) {
      return true
    }

    if (isEquivalent(state.tires.prevSearch.search, search.search)) {
        return false
    }

    if (state.tires.tires.length === 0) {
      return true
    }

    return true
  }
  
  export const fetchTiresIfNeeded = search => (dispatch, getState) => {
    if (shouldFetchTires(getState(), search, dispatch)) {
      return dispatch(fetchTires(search))
    }
  }

