import sendRequest from 'services/settings'

export function getCountries() {
    const raw = JSON.stringify({ 
        method: 'Pais.getItem',
        id: 1
    });

    const requestOptions = {
        method: 'POST',
        body: raw
    };

    return sendRequest('', requestOptions)
    .then(result => {
        return [result.item]
    }).catch(error => {
        console.log(error)
    })
}

export function getStatesByIdCountry(id_country) {
    const raw = JSON.stringify({
        method: 'Provincia.getListByIdPais',
        "id_pais": id_country
    });

    const requestOptions = {
        method: 'POST',
        body: raw
    };

    return sendRequest('', requestOptions)
    .then(result => {
        return result.list
    }).catch(error => {
        console.log(error)
    })
}

export function getPostalCodesByIdState(id_state) {
    const raw = JSON.stringify({
        "method": "Poblacion.getListCodigoPostalByIdProvincia",
        "id_provincia": id_state
    });

    const requestOptions = {
        method: 'POST',
        body: raw
    };

    return sendRequest('', requestOptions)
    .then(result => {
        return result.list
    }).catch(error => {
        console.log(error)
    })
}

export function getTownsByPostalCode(postal_code, id_state) {
    const raw = JSON.stringify({
        "method": "Poblacion.getListFilteredByCodigoPostal",
        "codigo_postal": postal_code,
        "id_provincia": id_state
    });

    const requestOptions = {
        method: 'POST',
        body: raw
    };

    return sendRequest('', requestOptions)
    .then(result => {
        return result.list
    }).catch(error => {
        console.log(error)
    })
}

export function create({user, voucher}) {
    console.log(voucher)
    const raw = JSON.stringify({
        "method": "PresupuestoTienda.create",
        "particular": true,
        "id_usuario": user.id,
        "fecha": null,
        "iva": 21,
        "tarifa": "tarifa1",
        "t_razon_social": user.razon_social,
        "t_id_poblacion": user.direccion.id_poblacion,
        "t_direccion": user.direccion.direccion,
        "t_telefono": user.telefono,
        "c_razon_social": voucher.nombre, 
        "c_cif": voucher.cif,
        "c_id_poblacion": user.direccion.id_poblacion,
        "c_direccion": user.direccion.direccion,
        "c_telefono": voucher.telefono,
        "c_email": voucher.email,
        "observaciones_texto": "",
        "observaciones_precio": "",
        "observaciones_cliente": voucher.observaciones_cliente,
        "enviado": "1" 
    });

    const requestOptions = {
        method: 'POST',
        body: raw
    };

    return sendRequest('', requestOptions)
    .then(result => {
        voucher.items.forEach(product => {
            addLine(result.item.id, product)
        });
        return result.item
    }).catch(error => {
        console.log(error)
    })
}

export function addLine(id_voucher, product) {
    const raw = JSON.stringify({
        "method": "PresupuestoTiendaLinea.save",
        "id_presupuesto": id_voucher,
        "id_producto": product.id_producto,
        "stars": 0,
        "precio": product.precioNeto,
        "ecotasa": product.ecotasa,
        "cantidad": product.quantity,
        "precio_montaje": product.hasMontaje,
        "precio_alineacion": product.hasAlineacion,
        "precio_inflado": product.hasInflado
    });

    const requestOptions = {
        method: 'POST',
        body: raw
    };

    return sendRequest('', requestOptions)
    .then(result => {
        return result.item
    }).catch(error => {
        console.log(error)
    })
}
