export const Tires = (state = {
  isLoading: false,
  tires: [],
  errorMessage: '',
  quantity: 4,
  montaje: true,
  alineacion: false,
  inflado: false
}, action) => {
    switch(action.type) {
      case 'REQUEST_TIRES':
        state.isLoading = true;
        state.prevSearch = action.search
          return {...state};
      case 'SET_TIRES':
          state.prevSearch = action.search
          state.isLoading = false
          state.tires = action.tires
          state.errorMessage = action.errorMessage
          state.quantity = 4
        
          return {...state};
      case 'REMOVE_TIRES':
        console.log("action")
          state.isLoading = false
          //state.tires = []
          state.errorMessage = ""
          state.quantity = 1
          state.prevSearch = {search: {
            measure: "",
            brokerType: "",
            filters: undefined
          }}
    
          return {...state};
      case 'CHANGE_QUANTITY':
          state.quantity = (action.quantity > 0) ? action.quantity : 1

          return {...state};
      case 'CHANGE_MONTAJE':
          state.montaje = action.montaje

          return {...state};
      case 'CHANGE_ALINEACION':
          state.alineacion = action.alineacion

          return {...state};
      case 'CHANGE_INFLADO':
          state.inflado = action.inflado

          return {...state};
      default:
        return state;
      }
  }
   
  export default Tires;