import styled from '@emotion/styled'

import { Link as LinkWouter } from "wouter"

//estilos comunes de botones
const commonButton = `
    border: none;
    background-color: var(--secondary-color);
    color: white;
    font-size: 20px;
    margin: 15px;
    min-height: 50px;
    padding: 0 10px;
    :hover {
        cursor: pointer;
    }
    :disabled {
        background-color: var(--light-secondary-color);
        border: 1px solid var(--secondary-color);
    }
`
//estilos comunes de botones
const commonPrimaryButton = `
    ${commonButton}
    background-color: var(--primary-color);
    color: white;
    :hover {
        color: var(--secondary-color);
    }
`
//estilos comunes de botones
const commonSecondaryButton = `
    ${commonButton}
    background-color: var(--secondary-color);
    color: white;
    :hover {
        color: var(--primary-color);
    }
`
/**
 * Estilos de botones de formulario
 */
export const Button = styled.button`
    ${commonButton}
`
export const ButtonPrimarySm = styled.button`
    ${commonPrimaryButton}
    font-size: 16px;
    min-height: 35px;
    margin: 5px;
    border-radius: 3px;
    font-weight: bold;
    padding: 20px;
`
export const ButtonSecondarySm = styled.button`
    ${commonSecondaryButton}
    font-size: 16px;
    min-height: 35px;
    margin: 5px;
    border-radius: 3px;
    font-weight: bold;
    padding: 20px;
`

export const ButtonWhatsapp = styled.button`
    border: none;
    font-size: 16px;
    min-height: 35px;
    margin: 5px;
    background-color: #1BD741;
    color: #FFF;
    border-radius: 3px;
    font-weight: bold;
    padding: 20px;
    :hover {
        cursor: pointer;
        color: var(--secondary-color);
    }
`

export const ButtonEmail = styled.button`
    border: none;
    font-size: 16px;
    min-height: 35px;
    margin: 5px;
    background-color: #0073C6;
    color: #FFF;
    border-radius: 3px;
    font-weight: bold;
    padding: 20px;
    :hover {
        cursor: pointer;
        color: var(--secondary-color);
    }
`

export const ButtonPrimaryXSm = styled.button`
    ${commonPrimaryButton}
    font-size: 9px;
    min-height: 20px;
    margin: 3px;
`
export const ButtonSecondaryXSm = styled.button`
    ${commonSecondaryButton}
    font-size: 9px;
    min-height: 30px;
    margin: 3px 0 0 0;
    width: 44px;
    border-radius: 3px;
    font-weight: bold;
`
export const LinkButton = styled(LinkWouter)`
    ${commonButton}
    text-decoration:none;
    align-items: center;
    background-color: var(--primary-color);
    color: var(--text-primary-color);
    display: flex;
    justify-content: center;
    :hover {
        color: var(--secondary-color);
    }
`