
/**
 * Valores iniciales del formulario de registro
 */
export const initialValues = () => {
    
    return {
        username: "",
        password: ""
    }
}
/**
 * Metodo que maneja la validacion del formulario
 */
export function validateFields(values) {
    const errors = {}
    if (!values.username) {
        errors.username = 'Campo obligatorio'
    }
    if (!values.password) {
        errors.password = 'Campo obligatorio'
    }
    return errors
}