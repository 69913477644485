import React, {useState} from 'react'
import {Link} from 'react-router-dom'

import './MainCard.css'

export default function MainCard(props) {
    const [touched, setTouched] = useState(false)
    return (
        (props.target === '_this') ? 
            <Link to={props.url} className={((props.featured) ? "featured" : "")}>
                <>
                    <div className={"Main-card" + ((touched) ? " active" : "")}
                        onTouchStart={() => setTouched(true)}
                        onTouchEnd={() => setTouched(false)}
                    >
                        {(props.image !== null) && <img src={props.image} alt={props.alt} />}
                        {(props.text !== null) && <h1>{props.text}</h1>}
                    </div>
                    {/* <div className={"Main-card-bottom" + ((touched) ? " active" : "")}>
                        <div className="b1"></div>
                        <div className="b2"></div>
                        <div className="b3"></div>
                        <div className="b4"></div>
                        <div className="b5"></div>
                        <div className="b6"></div>
                        <div className="b7"></div>
                        <div className="b8"></div>
                        <div className="b9"></div>
                        <div className="b10"></div>
                        <div className="b11"></div>
                        <div className="b12"></div>
                        <div className="b13"></div>
                        <div className="b14"></div>
                        <div className="b15"></div>
                        <div className="b16"></div>
                        <div className="b17"></div>
                        <div className="b18"></div>
                        <div className="b19"></div>
                        <div className="b20"></div>
                    </div> */}
                </>
            </Link> :
            <div className={"Main-card" + ((touched) ? " active" : "") + ((props.featured) ? " featured" : "")}
                onTouchStart={() => setTouched(true)}
                onTouchEnd={() => setTouched(false)}
                onClick={(e) => props.handleClick(e, props.target, props.url, props.title)}
            >
                {(props.image !== null) && <img src={props.image} alt={props.alt} />}
                {(props.text !== null) && <h1>{props.text}</h1>}
            </div>
    )
}