
import { formatFilters } from 'hooks/useUtils';

import sendRequest from 'services/settings'

export function getNormalTires({ userId, userDireccionId, measure, filters = '', page = 1 }) {
    const objectFilters = formatFilters(filters)
    const raw = JSON.stringify({ 
        method: 'Medida.search',
        id_usuario: userId,
        medida: measure,
        ip_request: '127.0.0.1',
        temporada: (objectFilters.filter_seasson !== undefined) ? objectFilters.filter_seasson : null,
        carga: (objectFilters.charge !== undefined) ? objectFilters.charge : null,
        velocidad: (objectFilters.speed !== undefined) ? objectFilters.speed : null,
        marca: (objectFilters.brand !== undefined) ? objectFilters.brand : null,
        runflat: (objectFilters.filter_runflat !== undefined) ? objectFilters.filter_runflat : null,
        autosellante: (objectFilters.filter_selfSealing !== undefined) ? objectFilters.filter_selfSealing : null,
        espuma_antiruido: (objectFilters.filter_antiNoiseFoam !== undefined) ? objectFilters.filter_antiNoiseFoam : null,
        carretera_campo: (objectFilters.filter_road !== undefined) ? objectFilters.filter_road : null,
        brokerType: 'tarifa1',
        id_direccion: userDireccionId,
        id_poblacion: null,
        no_register_search: true,
        stock0: null,
        oportunidad: null,
        imagenes: true
    });

    const requestOptions = {
        method: 'POST',
        body: raw
    };

    return sendRequest('', requestOptions)
    .then(result => {
        return result.list
    }).catch(error => {
        console.log(error)
    })
}

export function getMeasureItem( measure ) {
    const raw = JSON.stringify({ 
        method: 'Medida.getItem',
        medida: measure
    });

    const requestOptions = {
        method: 'POST',
        body: raw
    };

    return sendRequest('', requestOptions)
    .then(result => {
        return result.item
    }).catch(error => {
        console.log(error)
    })
}

export function getMeasure( ancho, perfil, llanta ) {
    const raw = JSON.stringify({ 
        method: 'Medida.getItemByParams',
        ancho: ancho,
        perfil: perfil,
        llanta: llanta
    });

    const requestOptions = {
        method: 'POST',
        body: raw
    };

    return sendRequest('', requestOptions)
    .then(result => {
        return result.item
    }).catch(error => {
        console.log(error)
    })
}

export function getAnchoList( {id_usuario} ) {
    const raw = JSON.stringify({ 
        method: 'Neumatico.getAnchoList',
        id_usuario: id_usuario
    });

    const requestOptions = {
        method: 'POST',
        body: raw
    };

    return sendRequest('', requestOptions)
    .then(result => {
        return result.list
    }).catch(error => {
        console.log(error)
    })
}

export function getPerfilList( {id_usuario, ancho} ) {
    const raw = JSON.stringify({ 
        method: 'Neumatico.getPerfilList',
        id_usuario: id_usuario,
        ancho: ancho
    });

    const requestOptions = {
        method: 'POST',
        body: raw
    };

    return sendRequest('', requestOptions)
    .then(result => {
        return result.list
    }).catch(error => {
        console.log(error)
    })
}

export function getLlantaList( {id_usuario, ancho, perfil} ) {
    const raw = JSON.stringify({ 
        method: 'Neumatico.getLlantaList',
        id_usuario: id_usuario,
        ancho: ancho,
        perfil: perfil
    });

    const requestOptions = {
        method: 'POST',
        body: raw
    };

    return sendRequest('', requestOptions)
    .then(result => {
        return result.list
    }).catch(error => {
        console.log(error)
    })
}

export function getCargaList( {id_usuario, ancho, perfil, llanta, velocity, brand} ) {
    const raw = JSON.stringify({ 
        method: 'Neumatico.getCargaList',
        id_usuario: id_usuario,
        ancho: ancho,
        perfil: perfil,
        llanta: llanta,
        velocidad: velocity,
        fabricante: brand
    });

    const requestOptions = {
        method: 'POST',
        body: raw
    };

    return sendRequest('', requestOptions)
    .then(result => {
        return result.list
    }).catch(error => {
        console.log(error)
        return []
    })
}

export function getVelocidadList( {id_usuario, ancho, perfil, llanta, charge, brand} ) {
    const raw = JSON.stringify({ 
        method: 'Neumatico.getVelocidadList',
        id_usuario: id_usuario,
        ancho: ancho,
        perfil: perfil,
        llanta: llanta,
        carga: charge,
        fabricante: brand
    });

    const requestOptions = {
        method: 'POST',
        body: raw
    };

    return sendRequest('', requestOptions)
    .then(result => {
        return result.list
    }).catch(error => {
        console.log(error)
        return []
    })
}

export function getFabricanteList( {id_usuario, ancho, perfil, llanta, charge, velocity} ) {
    const raw = JSON.stringify({ 
        method: 'Neumatico.getFabricanteList',
        id_usuario: id_usuario,
        ancho: ancho,
        perfil: perfil,
        llanta: llanta,
        carga: charge,
        velocidad: velocity
    });

    const requestOptions = {
        method: 'POST',
        body: raw
    };

    return sendRequest('', requestOptions)
    .then(result => {
        return result.list
    }).catch(error => {
        console.log(error)
        return []
    })
}
