import React from 'react'

import { Link } from 'react-router-dom'

import { PUBLIC_URL } from 'config/defines'

import './Header.css'
import logo from './images/logo.png'

export default function Header() {
    return (
        <div className="Header-container">
            <Link to="/">
                {/* <img src={`${PUBLIC_URL}/wp-content/uploads/2018/04/logo.png`} alt="TopRecambios"></img> */}
                {/* <img src="https://top-wp.desarrollogrupoics.com/wp-content/uploads/2021/04/LOGO_toprecambios.png" alt="TopRecambios"></img> */}
                <img src={logo} alt="TopRecambios"></img>
            </Link>
        </div>
    )
}
