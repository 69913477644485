export const SAVE_VOUCHER = 'SAVE_VOUCHER'
export const SET_VOUCHER = 'SET_VOUCHER'
export const ADD_VOUCHER_ITEM = 'ADD_VOUCHER_ITEM'
export const DELETE_VOUCHER_ITEM = 'DELETE_VOUCHER_ITEM'
export const UPDATE_VOUCHER_ITEM = 'UPDATE_VOUCHER_ITEM'
export const UPDATE_ITEM_CART = 'UPDATE_ITEM_CART'

export const requestVoucher = voucher => ({
    type: 'SAVE_VOUCHER',
    voucher
})

export const setVoucher = (voucher, err = null) => ({
  type: 'SET_VOUCHER',
  voucher: voucher,
  errorMessage: err,
  createdAt: Date.now()
})

export const addVoucherItem = (item, err = null) => ({
  type: 'ADD_VOUCHER_ITEM',
  item: item,
  errorMessage: err
})

export const deleteVoucherItem = (id, err = null) => ({
  type: 'DELETE_VOUCHER_ITEM',
  id: id,
  errorMessage: err
})

export const updateVoucherItem = (id, montaje, alineacion, inflado, quantity, err = null) => ({
  type: 'UPDATE_VOUCHER_ITEM',
  id: id,
  montaje: montaje,
  alineacion: alineacion,
  inflado: inflado,
  quantity: quantity,
  errorMessage: err
})
export const updateItemCart = (id, quantity, err = null ) =>({
  type:'UPDATE_ITEM_CART',
  id:id,
  quantity: quantity,
  errorMessage:err
})
