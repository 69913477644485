import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import { IMAGES_URL } from 'config/defines'

import { Col1, Col2, Row } from 'components/ElementsStyled/Containers'
import { H1 } from 'components/ElementsStyled/H'
import ModalIframe from 'components/ModalIframe/ModalIframe'
import Modal from 'components/Modal/Modal'
import { ButtonSecondarySm } from 'components/ElementsStyled/Button'
import { AddToCartHorizontal } from 'components/AddToCart/AddToCart'

import { fileExist, arraySearchId, beautifyPrice, tireImageExist } from 'hooks/useUtils'
import useModal from 'hooks/useModal';

import './TireDetail.css'

function TireDetail({ match, isLoading, tires, errorMessage }) {
    let history = useHistory()
    const [isOpenWhatsAppModal, openWhatsAppModal, closeWhatsAppModal, isCart] = useModal();
    const {id} = match.params

    const[show, setShow] = useState(false)
    const[src, setSrc] = useState("")
    const handleModal = (src, show) => {
        setSrc(src)
        setShow(show)
    }

    const tire = arraySearchId(tires, id)


    const firstImage = {id: 1, id_producto: tire.id, imagen: tire.imagen}
    const imagenes = [firstImage].concat(tire.imagenes)
    const [imagenPrincipal, setImagenPrincipal] = useState(0)
    const handleImagenes = (key) => {
        setImagenPrincipal(key)
    }

    useEffect(() => {
        const l = imagenes.length
        const interval = setInterval(() => {
            setImagenPrincipal(imagenPrincipal => (imagenPrincipal + 1 === l) ? 0 : imagenPrincipal + 1)
        }, 5000);
        return () => clearInterval(interval);
      }, []);

    return <div className="TireDetail-container">
        <Row style={{ justifyContent: 'space-between' }}>
            <H1 className="title">Detalle del producto</H1>
            <ButtonSecondarySm onClick={ () => history.goBack() }><FontAwesomeIcon icon={ faSearch } /> Volver</ButtonSecondarySm>
        </Row>
        <Row>
            <Col2>
                <Row>
                    <Col2>
                        <h2>{tire.m_nombre + " " + tire.nombre}</h2>
                    </Col2>
                    <Col1>
                        <h2 style={{ "color": "var(--primary-color)" }}>{beautifyPrice(tire.precioNeto)}</h2>
                    </Col1>
                    <Col1 style={{ "padding-top": "20px" }}>
                        <AddToCartHorizontal
                            orientation="horizontal"
                            item={tire} />
                    </Col1>
                </Row>
            </Col2>
            <Col1></Col1>
        </Row>
        <Row>
            <Col2>
                <Row style={{ justifyContent: 'center' }}>
                    {(fileExist(`${IMAGES_URL}/tyre_label/ec_labels/${tire.referencia}.png`)) ?
                        <ButtonSecondarySm  onClick={ openWhatsAppModal }>Ver etiqueta europea</ButtonSecondarySm>
                        : null
                    }
                    {(tire.id_eprel !== "0" && tire.url_eprel !== "") ?
                        <ButtonSecondarySm onClick={ () => handleModal(tire.url_eprel, true) }>Información Eprel</ButtonSecondarySm>
                    : null
                    }
                </Row>
                <Row className="imageSlider">
                    <Col1>
                        <div className="imageSlider-principal">
					        <img src={tireImageExist(imagenes[imagenPrincipal].imagen)} alt={tire.nombre} height="400" width="auto" />
                        </div>
                        <div className="imageSlider-thumbnailRow">
                            <Row>
                                {
                                    imagenes.map((imagen, key) => {
                                        var className =  ( imagenPrincipal === key) ? 'selected' : ''
                                        className = className + " imageSlider-thumbnail"
                                        return <div className={className}>
                                            <img onClick={() => handleImagenes(key)} src={tireImageExist(imagen.imagen)} alt={imagen.imagen} height="100" width="auto" />
                                        </div>
                                    })
                                }
                            </Row>
                        </div>
                    </Col1>
                </Row>
            </Col2>
            <Col1></Col1>
            <Col2>
                <Row>
                    <H1 className="subtitle">Características Técnicas</H1>
                </Row>
                <Row>
                    <Col1 className="technical">
                        <Row className="row">
                            <span>REFERENCIA</span> <span>{tire.referencia}</span>
                        </Row>
                        <Row className="row">
                            <span>EAN</span> <span>{tire.ean}</span>
                        </Row>
                        <Row className="row">
                            <span>MARCA</span> <span>{tire.m_nombre}</span>
                        </Row>
                        <Row className="row">
                            <span>ANCHO</span> <span>{tire.ancho}</span>
                        </Row>
                        <Row className="row">
                            <span>Perfil</span> <span>{tire.perfil}</span>
                        </Row>
                        <Row className="row">
                            <span>LLANTA</span> <span>{tire.llanta}</span>
                        </Row>
                        <Row className="row">
                            <span>CARGA</span> <span>{tire.carga}</span>
                        </Row>
                        <Row className="row">
                            <span>VELOCIDAD</span> <span>{tire.velocidad}</span>
                        </Row>
                        <Row className="row">
                            <span>TIPO</span>
                            <span>
                                <Col1>
                                    <div className={"bg-icon bg-vehicle_type_" + tire.tipo_vehiculo.codigo} alt={tire.tipo_vehiculo.nombre} title={tire.tipo_vehiculo.nombre}></div>
                                    <div className={"bg-icon bg-tyre_type_" + tire.tipo_neumatico.codigo} alt={tire.tipo_neumatico.nombre} title={tire.tipo_neumatico.nombre}></div>
                                </Col1>
                            </span>
                        </Row>
                        <Row className="row">
                            <span><strong>{(tire.id_eprel !== "0") ? "2020/740" : "1222/2009"}</strong></span> <span></span>
                        </Row>
                        <Row className="row">
                            <span>EFICIENCIA CONSUMO</span> <span>{tire.consumo}</span>
                        </Row>
                        <Row className="row">
                            <span>EFICIENCIA FRENADA</span> <span>{tire.frenada}</span>
                        </Row>
                        <Row className="row">
                            <span>EFICIENCIA SONORIDAD</span> <span>{
                                                                        (tire.nivel_sonoridad) ? <div className={"bg-icon bg-eficiencia_sonoridad_" + tire.nivel_sonoridad} style={{float: "left"}}></div> : ''
                                                                    }{tire.sonoridad}dB</span>
                        </Row>
                        <Row className="row">
                            <span>STOCK</span> <span>{(tire.stockTotal > 12) ? "+12" : tire.stockTotal}</span>
                        </Row>
                    </Col1>
                </Row>
            </Col2>
        </Row>
        <Modal
            isOpen = { isOpenWhatsAppModal }
            closeModal = { closeWhatsAppModal }
            title = 'Etiqueta Europea'
            isCart = {isCart}
        >
            <img src={`${IMAGES_URL}/tyre_label/ec_labels/${tire.referencia}.png`} alt="Etiqueta europea"></img>
        </Modal>
        <ModalIframe src={src} title="Información Eprel" show={show} closeModal={() => setShow(false)}/>
    </div>
}

const mapStateToProps = (state, ownProps) => {
    let {isLoading, tires, errorMessage} = state.tires
    let { id } = ownProps

    return {
        isLoading,
        tires,
        errorMessage,
        id
    }
}

export default connect(mapStateToProps) (TireDetail)