import React, { useEffect, useState } from 'react'

import { getUserData } from '../services/login'

const Context = React.createContext({});

export function UserContextProvider({ children }) {
    const [userId, setUserId] = useState(
        () => window.localStorage.getItem('id')
    )
    const [userData, setUserData] = useState(null)

    useEffect(() => {
        if (userId) {
            if (!userData) {
                getUserData({userId}).then(ud => {
                    const direccion = ud.direccion[Object.keys(ud.direccion)[0]]
                    ud.direccion = direccion
                    setUserData(ud)
                })
            }
        }
    }, [userId, userData])

    return <Context.Provider value={{
        userData,
        userId,
        setUserData,
        setUserId
    }}>
        {children}
    </Context.Provider>
}

export default Context;