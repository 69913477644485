import React from 'react'
import Modal from 'react-modal'

import './ModalIframe.css'
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
}
Modal.setAppElement('#root');
export default function ModalIframe(props) {
    return (
        <Modal
            isOpen={props.show}
            style={customStyles}
            contentLabel={props.title}
            onRequestClose={props.closeModal}
            shouldCloseOnOverlayClick={true}
            >
            <div className="ModalIframe-container">
                <iframe title={props.title} src={props.src} width="1080" height="800"/>
            </div>
        </Modal>
    )
}