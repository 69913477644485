import sendRequest from './settings'

export function getUserData({ userId }) {
    const raw = JSON.stringify({ method: 'Usuario.getItem', id: userId});

    const requestOptions = {
        method: 'POST',
        body: raw,
    };

    return sendRequest(``, requestOptions)
    .then(result => {
        return result.item
    })
}

export default function login({ username, password }) {
    const raw = JSON.stringify({ method: 'Usuario.login', username: username, password: password, force: false });

    const requestOptions = {
        method: 'POST',
        body: raw,

    };

    return sendRequest('', requestOptions)
}
