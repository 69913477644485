import { IMAGES_URL } from 'config/defines';
import sendRequest from 'services/settings';

/**
 * 
 * @param {string} urlToFile 
 * 
 * @return {bool}
 * 
 * Comprueba si un archivo existe en el servidor
 */

export default function fileExist (urlToFile) {

    /* console.log(urlToFile)
    const raw = JSON.stringify({ 
        method: 'Test.fileExist',
        url: urlToFile
    });

    const requestOptions = {
        method: 'POST',
        body: raw
    };

    sendRequest("", requestOptions, 'https://toprecambios.com')
    .then(result => {
        
        return true
    }).catch(error => {
        return true
    }) */
    var xhr = new XMLHttpRequest();
    try{
        xhr.open('HEAD', urlToFile, false);
        xhr.send();
        //console.log(xhr.status)
        if (xhr.status !== 200) {
            return false;
        } else {
            return true;
        }
    }
    catch(e) {
        //console.log(e)
        return false;
    }
    //return true;
}

/**
 * 
 * @param {string} filename 
 * 
 * @return {string}
 * 
 * Comprueba si la imágen de un neumático existe y si no devuelve una imágen por defecto
 * 
 */

export function tireImageExists(filename) {
    var urlToFile = IMAGES_URL + "/neumaticos/" + filename
    var exists = fileExist(urlToFile)
    if (!exists) {
        urlToFile = "https://toprecambios.desarrollogrupoics.com/web/img/neumaticos/upload/" + filename
        exists = fileExist(urlToFile)
    }
    return (exists) ? urlToFile : "/images/tires/broken.png"
}

export function getTireImagesUrl(filename) {
    return IMAGES_URL + "/neumaticos/" + filename
}

/**
 * 
 * @param {string} filename 
 * 
 * @return {string}
 * 
 * Comprueba si la imágen de una batería existe y si no devuelve una imágen por defecto
 * 
 */

export function batteryImageExists(filename) {
    const urlToFile = "/images/battery/" + filename
    const exists = fileExist(urlToFile)
    return (exists) ? urlToFile : "/images/battery/broken.png"
}

/**
 * 
 * @param {string} filename 
 * 
 * @return {string}
 * 
 * Comprueba si la imágen de una marca existe y si no devuelve una imágen por defecto
 * 
 */

 export function getBrandImagesUrl(filename) {
    return IMAGES_URL + "/marcas/o/" + filename
}

export function brandImageExists(filename) {
    const urlToFile = IMAGES_URL + "/marcas/" + filename
    const exists = fileExist(urlToFile)
    return (exists) ? urlToFile : "/images/marcas/o/broken.png"
}
