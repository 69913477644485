/**
 * Valores iniciales del formulario de registro
 */
export const initialValues = {
    nombre: '',
    telefono: '',
    email: '',
    observaciones_cliente: ''
}

/**
 * Metodo que maneja la validacion del formulario
 */
export function validateFields(values) {
    const errors = {}
    if (!values.nombre) {
        errors.nombre = 'Campo obligatorio'
    }
    if (!values.telefono) {
        errors.telefono = 'Campo obligatorio'
    }
    let emailRegex = /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i
    if (!values.email) {
        errors.email = 'Campo obligatorio'
    } else if (!emailRegex.test(values.email)) {
        errors.email = 'Campo incorrecto'
    }
    if (values.observaciones_cliente.length > 500) {
        errors.nombre = 'Máximo 500 caracteres'
    }

    return errors
}
/**
 * Metodo que maneja el envio del formulario
 */
export default function onSubmit(values, { setFieldError, resetForm, setStatus }) {
    /*return registry(values).then(result => {
        resetForm()
        setStatus({
            success: result.message
        })
    }).catch(err => {
        //err.message
        setFieldError('result', err.message)
    })*/
}