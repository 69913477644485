import React from "react";
import { useHistory } from "react-router-dom"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { Row } from "components/ElementsStyled/Containers";
import { H1 } from "components/ElementsStyled/H";
import { ButtonSecondarySm } from 'components/ElementsStyled/Button'

import './Privacy.css'

export default function Privacy() 
{
    let history = useHistory()
    return (
        <div className="Privacy-container">
            <Row style={{ justifyContent: 'space-between' }}>
                <H1 className="title">Política de Protección de Datos</H1>
                <ButtonSecondarySm onClick={ () => history.goBack() }><FontAwesomeIcon icon={ faArrowLeft } /> Volver</ButtonSecondarySm>
            </Row>
            <h2>Política de Protección de Datos de Carácter Personal</h2>
            <p>El titular de la web y responsable del tratamiento, <strong>TOP RECAMBIOS, S.L.</strong> con domicilio en C/ Censal, 12 de Alginet (46230) Valencia y CIF: B-96148028 es el titular del Portal y lo pone a disposición de los usuarios de Internet, con el fin tanto de proporcionar información sobre los servicios que presta <strong>TOP RECAMBIOS, S.L.</strong>, así como el de permitir la prestación de servicios a través del Portal.</p>

            <h2>Datos de Contacto</h2>
            <p>Teléfono de contacto: <strong>+34 963 79 04 03</strong>.</p>
            <p>Para cualquier gestión o solicitud con respecto a la privacidad de <strong>TOP RECAMBIOS, S.L.</strong>, el usuario podrá dirigirse a nuestra dirección de correo electrónico a info@toprecambios.com.</p>
            
            <h3>1.- Finalidad ¿Con qué finalidades tratamos sus datos?</h3>
            <p><strong>TOP RECAMBIOS, S.L.</strong> informa al Usuario de la existencia de un tratamiento de datos de carácter personal (tratamiento denominado “USUARIOS WEB”) creado con los datos obtenidos en la página web por y para <strong>TOP RECAMBIOS, S.L.</strong> y bajo su responsabilidad.<br/>
            Dicho tratamiento ha sido creado con la finalidad de facilitar información sobre la prestación de los servicios ofrecidos por el Portal, así como atender y gestionar las consultas realizadas por los usuarios a través de esta vía.<br/>
            Los plazos de conservación de los datos que los usuarios nos proporcionen a través de los formularios presentes en la web son los siguientes:</p>
            <ul>
                <li>En el caso de consultas: Una vez contestada la consulta, el año en curso</li>
                <li>En el caso de contratar alguno de nuestros servicios: Mientras dure la relación contractual</li>
            </ul>
            
            <h3>2.- Legitimación ¿Cuál es la legitimación para el tratamiento de sus datos?</h3>
			<p>La base legal para el tratamiento de los datos personales de los usuarios es el propio consentimiento del interesado</p>
            <p>Asimismo, la oferta prospectiva de servicios está basada en el consentimiento que se solicita al usuario sin que ningún caso la retirada de este consentimiento condicione la ejecución de otras finalidades.</p>
            <p>El usuario no tiene la obligación de facilitarnos sus datos personales, pero en caso de no facilitarnos los datos mínimos solicitados, no se podrán llevar a cabo las operaciones que se ofrecen online en nuestra web, tales como atender las consultas, contactar, dejar un comentario, participar y/o acceder a según qué apartados de la web.</p>
            <p>Además, informamos que el usuario podrá revocar el consentimiento para la recogida y tratamiento de sus datos personales en cualquier momento, mediante el procedimiento que establecemos para ello, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.</p>

            <h3>3.- Destinatarios ¿A qué destinatarios se comunicarán sus datos?</h3>
            <p>Los datos que se recogen en esta web mediante los formularios correspondientes no serán cedidos a terceros y únicamente podrán ser objeto de comunicación a otras empresas que ejercen de encargados del tratamiento de datos personales, y que solo tratarán los datos conforme a las estrictas indicaciones que <strong>TOP RECAMBIOS, S.L.</strong> establece con dichos encargados. En todo caso los datos serán comunicados dentro del espacio de la Unión Europea y solo para los fines anteriormente indicados.</p>

            <h3>4.- Derechos ¿Cuáles son sus derechos cuando nos facilita sus datos?</h3>
			<p>A los usuarios de la web titularidad de <strong>TOP RECAMBIOS, S.L.</strong> se les garantiza los derechos que se establecen en la legislación en materia de Protección de Datos Personales:</p>
            <p>En primer lugar, el usuario tiene derecho a obtener una confirmación sobre si en<strong> <strong>TOP RECAMBIOS, S.L.</strong></strong> se están tratando datos personales que le conciernan o no.</p>
            <p>Los usuarios tendrán derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos que sean inexactos o, en su caso, solicitar su supresión, oposición o limitación cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos, así como la portabilidad para facilitar sus datos personales a un nuevo responsable.</p>
            <p>Los usuarios tienen derecho a oponerse al tratamiento de sus datos personales, por lo que <strong>TOP RECAMBIOS, S.L.</strong> dejará de tratar sus datos, salvo si existen motivos legítimos, imperiosos, o el ejercicio o la defensa de posibles reclamaciones.</p>
            <p>Los usuarios tienen la facultad de solicitar a <strong>TOP RECAMBIOS, S.L.</strong> que limite los tratamientos de sus datos personales cuando se cumplan los requisitos que el Reglamento UE 2016/679 y la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales exigen.</p>
            <p><strong>TOP RECAMBIOS, S.L.</strong> le informa que tiene implantadas las medidas de seguridad de índole técnica y organizativas necesarias que garanticen la seguridad de sus datos de carácter personal y eviten su alteración, pérdida, tratamiento y/o acceso no autorizado, según lo dispuesto en el RGPD 2016/679 de 27 de abril de 2016 y la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales.</p>
            <p>Por último, informamos que cualquier interesado podrá presentar una reclamación ante la Agencia Española de Protección de Datos, especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos.</p>

            <h3>5.- Comunicaciones Comerciales</h3>
			<p><strong>TOP RECAMBIOS, S.L.</strong> podrá enviar comunicaciones comerciales por cualquier medio con pleno respeto a la legislación que en materia de protección de datos personales se encuentre vigente.</p>
            <p>Para las comunicaciones comerciales realizadas a través del correo electrónico y medios equivalentes en cumplimiento de lo dispuesto en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, será debidamente recabado el consentimiento del Usuario en el momento de recoger los datos correspondientes.</p>
            <p>El Usuario podrá revocar en cualquier momento el consentimiento prestado a la recepción de las comunicaciones personales con la simple notificación de su voluntad a <strong>TOP RECAMBIOS, S.L.</strong> a nuestra dirección de correo electrónico info@toprecambios.com</p>

        </div>
    )
}