import React, {useState, useEffect} from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import beautifyPrice from 'hooks/beautifyPrice'

import { updateVoucherItem } from 'redux/actions/voucher';
import store from 'redux/index';

import "./VoucherProductsLine.css"

export default function VoucherProductLine({ key, item, handleDelete, alineacion, setAlineacion, montaje, setMontaje, inflado, setInflado }) {

    /**
     * @TODO Revisar "Factura" para hacer coincidir el precio neto con el de web.
     * El resto de cálculos parecen estar bien.
     */
    const [quantity, setQuantity] = useState(item.quantity)
    const handleQuantity = (event) => {
        setQuantity(event.target.value)
    }
    

    /* useEffect(() => store.subscribe(() => {
        if(!checkbox.current.checked && store.getState().tires.quantity <= item.stock) {
            setQuantity(store.getState().tires.quantity)
        }
    }), []); */

    useEffect(() => {
        if (item.precio_montaje !== undefined) {
            setPrecioMontaje(item.precio_montaje)
        }
    }, [item.precio_montaje])

    item.precio_montaje = parseFloat(item.precio_montaje)
    item.quantity = parseInt(item.quantity)
    const [precio_montaje, setPrecioMontaje] = useState(0)

    item.precio_montaje = (item.llanta <= 16 && item.id_tipo_vehiculo === "1") ? item.montaje_ne8 : item.montaje_ne3
    const handleMontaje = () => {
        setMontaje(!montaje)
    }

    const [precio_alineacion, setPrecioAlineacion] = useState(0)
    item.precio_alineacion = "0"
    if (item.id_tipo_vehiculo === "1") {
        item.precio_alineacion = item.alineacion_ne7
    } else if (item.id_tipo_vehiculo === "3" || item.id_tipo_vehiculo === "4") {
        item.precio_alineacion = item.alineacion_ne9
    }
    const handleAlineacion = () => {
        setAlineacion(!alineacion)
    }

    const [precio_inflado, setPrecioInflado] = useState(0)
    const handleInflado = () => {
        setInflado(!inflado)
    }

    const [total, setTotal] = useState(parseFloat(item.precioNeto) + parseFloat(item.ecotasa))
    useEffect(() => {
        setTotal((parseFloat(item.precioNeto) + parseFloat(item.ecotasa) + parseFloat(precio_inflado) + parseFloat(precio_montaje)) * quantity + parseFloat(precio_alineacion))

        store.dispatch(updateVoucherItem(item.id, precio_montaje, precio_alineacion, precio_inflado, quantity))
    }, [precio_montaje, precio_alineacion, precio_inflado, quantity])

    const [iva, setIva] = useState(((item.total + parseFloat(precio_inflado) + parseFloat(precio_montaje)) * 0.21) * parseInt(quantity) + (parseFloat(precio_alineacion) * 0.21))
    const [lineTotal, setLineTotal] = useState((item.total + parseFloat(precio_inflado) + parseFloat(precio_montaje)) * parseInt(quantity) + parseFloat(precio_alineacion) + iva)
    useEffect(() => {
        setIva(parseFloat(total) * 0.21)
    }, [total])

    useEffect(() => {
        setLineTotal(total + iva)
    }, [iva])
    

    useEffect(() => {
        (montaje) ? setPrecioMontaje(item.precio_montaje) : setPrecioMontaje(0)
    }, [montaje])

    useEffect(() => {
        (alineacion) ? setPrecioAlineacion(item.precio_alineacion) : setPrecioAlineacion(0)
    }, [alineacion])

    useEffect(() => {
        (inflado) ? setPrecioInflado(item.inflado_ni) : setPrecioInflado(0)
    }, [inflado])
    return (
        <table>
                    {/* <thead>
                        <td>Referencia</td>
                        <td>Descripción</td>
                        <td>Stock</td>
                        <td>Cantidad</td>
                        <td>Precio</td>
                        <td>Ecotasa</td>
                        <td>Total</td>
                        <td>Eliminar</td>
                    </thead> */}
                    <tbody>
                        <tr key={key}>
                            <td><small><strong>Ref.</strong></small> <strong>{item.referencia}</strong></td>
                            <td><strong>{item.m_nombre} {item.nombre}</strong></td>
                            <td><small><strong>Stock</strong></small> <br></br>{item.stockTotal > 12 ? "+12" : item.stockTotal}</td>
                            <td>
                                <select onChange={(e) => handleQuantity(e)}>
                                    {
                                        [...Array(parseInt(item.stockTotal))].map((i, key) => {
                                            return <option key={key + 1} selected={(key + 1) === quantity} value={key + 1}>{key + 1}</option>
                                        })
                                    }
                                </select>
                            </td>
                            <td><small><strong>Precio</strong></small> <br></br>{beautifyPrice(item.precioNeto)}</td>
                            <td><small><strong>Ecotasa</strong></small> <br></br>{beautifyPrice(item.ecotasa)}</td>
                            <td className="VoucherProductsLine-subtotal"><small><strong>Subtotal.</strong></small> <br></br>{beautifyPrice((parseFloat(item.precioNeto) + parseFloat(item.ecotasa)) * quantity)}</td>
                            <td><button onClick={() => handleDelete(item.id)}><FontAwesomeIcon icon={faTrashAlt} />{/* 🗑 */}</button></td>
                        </tr>
                        <tr>
                            <td>
                                <label className="control control-checkbox">
                                    <input type="checkbox" name="precioMontaje" value={precio_montaje} onChange={handleMontaje} checked={montaje}></input>
                                    <div className="control_indicator"></div>
                                </label>
                            </td>
                            <td>Montaje</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{beautifyPrice(precio_montaje * item.quantity)}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <label className="control control-checkbox">
                                    <input type="checkbox" name="precioAlineacion" value={precio_alineacion} onChange={handleAlineacion} checked={alineacion}></input>
                                    <div className="control_indicator"></div>
                                </label>
                            </td>
                            <td>Alineación</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{beautifyPrice(precio_alineacion)}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                <label className="control control-checkbox">
                                    <input type="checkbox" name="inflado_ni" value={precio_inflado} onChange={handleInflado} checked={inflado}></input>
                                    <div className="control_indicator"></div>
                                </label>
                            </td>
                            <td>Inflado con nitrógeno</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{beautifyPrice(precio_inflado * item.quantity)}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>IVA (21%)</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{beautifyPrice(iva)}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td><strong>Total</strong></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><strong>{beautifyPrice(lineTotal)}</strong></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
    )
}