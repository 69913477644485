import { useState } from 'react'

const useModal = () => {

    const [isOpenModal, setIsOpenModal] = useState(false)

    const openModal = () => {
        setIsOpenModal(true)
    }

    const closeModal = () => {
        setIsOpenModal(false)
    }

    const  openCart = () => {
        setIsOpenModal(true)
    }
    return [isOpenModal, openModal, closeModal, openCart]
}

export default useModal
