import { useCallback, useContext } from 'react'

import Context from 'context/UserContext'

import loginService from 'services/login'

export default function useUser() {
    const {
        userData, setUserData,
        userId, setUserId,
    } = useContext(Context)

    /**
     * Metodo que ejecuta una llamada servicio de login enviando usuario y contraseña
     * Retorna un jwt y un item con los datos necesarios del usuario
     */
    const login = useCallback(({ username, password }) => {
        return loginService({ username, password })
            .then(result => {
                const { item } = result
                if (item.id_tienda === null || isNaN(item.id_tienda)) {
                    //Si no es una tienda denegamos el acceso
                    window.localStorage.removeItem('id')
                    throw "Acceso restringido";
                    //return false
                }
                window.localStorage.setItem('id', item.id)
                const direccion = item.direccion[Object.keys(item.direccion)[0]]
                item.direccion = direccion
                setUserData(item)
                setUserId(item.id)
                return true
            }).catch(err => {
                window.localStorage.removeItem('id')
                throw err
            })
    }, [setUserData, setUserId])

    /**
     * Metodo que cierra session
     */
    const logout = useCallback(() => {
        console.log("cerrar sesion")
        window.localStorage.removeItem('id')
        setUserData(null)
        setUserId(null)
    }, [setUserData, setUserId])

    /**
     * Metodo que recibe un texto de un permiso y devuelve su valor o false si no lo tiene
     */
    const getPermissionValue = useCallback((permission) => {
        const { permissions } = userData
        if (permissions.hasOwnProperty(permission)) {
            return permissions[permission].value
        }
        return null
    }, [userData])

    return {
        isLogged: Boolean(userId),
        isLoad: Boolean(userData),
        getPermissionValue,
        login,
        logout,
    }
}
