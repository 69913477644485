import React, { useContext, useState } from 'react'
import { Link, useHistory } from "react-router-dom"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faArrowLeft, faEnvelope, faPaperPlane, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import { Form, Field, Formik, ErrorMessage } from 'formik'
import { initialValues, validateFields } from './FormikConfig'

import { ButtonPrimarySm, ButtonSecondarySm, ButtonWhatsapp, ButtonEmail } from 'components/ElementsStyled/Button'
import Modal from 'components/Modal/Modal'
import VoucherProductLine from './VoucherProductLine';
import { H1 } from 'components/ElementsStyled/H';
import { Row } from 'components/ElementsStyled/Containers';

import { WEB_URL } from 'config/defines'

import Context from 'context/UserContext'

import useModal from 'hooks/useModal';

import store from 'redux/index';
import { deleteVoucherItem, setVoucher } from 'redux/actions/voucher';

import { create } from 'services/voucher'

import "./VoucherProducts.css"

export default function VoucherProducts({products, voucher, handleFinish, canConfirm}) {
    let history = useHistory()
    const [render, setRender] = useState(0)
    const handleDelete = (id) => {
        store.dispatch(deleteVoucherItem(id))
        setRender(id) 
    }
    const handleAddMore = () => {
        history.push("/neumaticos")
    }

    const [condiciones, setCondiciones] = useState(false)
    const handleCondiciones = (e) => {
        setCondiciones(!condiciones)
    }


    const [isOpenWhatsAppModal, openWhatsAppModal, closeWhatsAppModal] = useModal();

    const { userData } = useContext(Context)

    const [mode, setMode] = useState(null)

    const [top, setTop] = useState('')

    const handleOpenModal = (type) => {
        setMode(type)
        openWhatsAppModal()
    }
    

    const [sended, setSended] = useState(false)

    const handleSubmit = (values, {setSubmitting}) => {
        const state = store.getState();
        const id_poblacion = userData.direccion.id
        const v = {
            "nombre": values.nombre,
            "cif": "",
            "telefono": values.telefono,
            "email": values.email,
            "observaciones_cliente": values.observaciones_cliente,
            "items": (state.voucher.voucher !== false) ? state.voucher.voucher.items : [],
            "mode": mode,
        }

        const userInfo = btoa(JSON.stringify(v))

        create({
            user: userData,
            voucher: v
        }).then(item => {
            fetch(`${WEB_URL}/presupuesto_tienda_pdf_particular.php?id=${item.id}&id_usuario=${userData.id}&id_poblacion=${id_poblacion}&username=${userData.username}&send=1&particular=1&mode=${mode}`)
                .then(res => {
                    if (res.ok) {
                        setSubmitting(false)
                        setSended(false)
                        res.text().then((data) => {
                            if (data === "ok") {
                                const voucher = {
                                    "pais": "",
                                    "id_pais": "",
                                    "provincia":"",
                                    "id_provincia": "",
                                    "codigo_postal": "",
                                    "poblacion": "",
                                    "id_poblacion": "",
                                    "razon_social": "",
                                    "cif": "",
                                    "direccion": "",
                                    "telefono": "",
                                    "email": "",
                                    "items": [],
                                }
                                store.dispatch(setVoucher(voucher))
                                setSubmitting(false)
                                setSended(false)
                                if (false) {
                                    setSended(true)
                                    setTimeout(() => {
                                        setSended(false)
                                        window.location.reload();
                                    }, 3000)
                                } else {
                                    history.push(`/presupuesto-enviado/${userInfo}`);
                                }
                                //setCanConfirm(false)
                            } else {
                                setSubmitting(false)
                                setSended(true)
                            }
                        })
                    } else {
                        setSubmitting(false)
                        setSended(true)
                    }
                })
                .catch(error => {
                    setSubmitting(false)
                    setSended(true)
                })
                /*.then((response) => {
                    setSended(true);
                    setSubmitting(false)
                    console.log("ERROOOOOOR")
                }, (response) => {
                    const voucher = {
                        "pais": "",
                        "id_pais": "",
                        "provincia":"",
                        "id_provincia": "",
                        "codigo_postal": "",
                        "poblacion": "",
                        "id_poblacion": "",
                        "razon_social": "",
                        "cif": "",
                        "direccion": "",
                        "telefono": "",
                        "email": "",
                        "items": [],
                    }
                    store.dispatch(setVoucher(voucher))
                    setSubmitting(false)
                    setSended(false)
                    if (false) {
                        setSended(true)
                        setTimeout(() => {
                            setSended(false)
                            window.location.reload();
                        }, 3000)
                    } else {
                        history.push(`/presupuesto-enviado/${userInfo}`);
                    }
                    //setCanConfirm(false)
                }) */
            //console.log(response)
        })
    }

    const [montaje, setMontaje] = useState(true)
    const [alineacion, setAlineacion] = useState(false)
    const [inflado, setInflado] = useState(false)

    return <div className="VoucherProducts-container">
        <Row>
            <H1 className="title">Productos</H1>
        </Row>
        {
        (products !== undefined && products.length > 0) ?
        <>
            {/* <ButtonSecondarySm onClick={ handleAddMore }><FontAwesomeIcon icon={ faArrowLeft } /></ButtonSecondarySm> */}
            <ButtonSecondarySm onClick={ handleAddMore }><FontAwesomeIcon icon={ faSearch } /> Añadir más productos</ButtonSecondarySm>
            {/* <ButtonWhatsapp onClick={ () => handleOpenModal('WhatsApp') }><FontAwesomeIcon icon={ faWhatsapp } /> Enviar por WhatsApp</ButtonWhatsapp> */}
            <ButtonEmail onClick={ () => handleOpenModal('Email') }><FontAwesomeIcon icon={ faEnvelope } /> Enviar por Correo</ButtonEmail>
            <Modal
                isOpen = { isOpenWhatsAppModal }
                closeModal = { closeWhatsAppModal }
                title = {(mode === 'WhatsApp') ? 'Enviar por WhatsApp' : 'Enviar por Email'}
                className={top}
            >
                <Formik initialValues={initialValues} validate={validateFields} onSubmit={handleSubmit}>
                {({isSubmitting, status, setFieldValue}) =>
                    <Form autoComplete="off">
                        <div className="Form-row">
                            <div className="Form-col col-1">
                                <Field
                                    type="text"
                                    name="nombre"
                                    placeholder="Nombre"
                                    autoComplete="off"
                                    className="VoucherProducts-text"
                                    onFocus={() => {setTop("top")}}
                                    onBlur={() => {setTop("")}}
                                />
                                    <ErrorMessage name="nombre">
                                        {msg => <span className="tooltip">{msg}</span>}
                                    </ErrorMessage>
                            </div>
                        </div>
                        <div className="Form-row">
                            <div className="Form-col col-1">
                                <Field
                                    type="tel"
                                    name="telefono"
                                    placeholder="Teléfono"
                                    autoComplete="off"
                                    className="VoucherProducts-text"
                                    onFocus={() => {setTop("top")}}
                                    onBlur={() => {setTop("")}}
                                />
                                    <ErrorMessage name="telefono">
                                        {msg => <span className="tooltip">{msg}</span>}
                                    </ErrorMessage>
                            </div>
                        </div>
                        <div className="Form-row">
                            <div className="Form-col col-1">
                                <Field
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    autoComplete="off"
                                    className="VoucherProducts-text"
                                    onFocus={() => {setTop("top")}}
                                    onBlur={() => {setTop("")}}
                            />
                                    <ErrorMessage name="email">
                                        {msg => <span className="tooltip">{msg}</span>}
                                    </ErrorMessage>
                            </div>
                        </div>
                        <div className="Form-row">
                            <div className="Form-col col-1">
                                <Field
                                    component="textarea"
                                    name="observaciones_cliente"
                                    placeholder="Observaciones"
                                    autoComplete="off"
                                    className="VoucherProducts-text"
                                    onFocus={() => {setTop("top")}}
                                    onBlur={() => {setTop("")}}
                            />
                                    <ErrorMessage name="observaciones">
                                        {msg => <span className="tooltip">{msg}</span>}
                                    </ErrorMessage>
                            </div>
                        </div>
                        <label for="condiciones"><input type="checkbox" name="condiciones" onChange={(e) => handleCondiciones(e)} value="1" />Aceptar la <Link to="/politica-privacidad">política de privacidad</Link>.</label>
                        <br></br><br></br>
                        { (isSubmitting) ? 
                            <>
                                <p className="text-blue font-small">Enviando...</p>
                                <br></br><br></br>
                            </>
                            : (sended) ?
                                <>
                                    <p className="text-red font-small">
                                        Se ha producido un error, revise los datos e inténtelo de nuevo.
                                    </p>
                                    <br></br><br></br>
                                </>
                                : ""
                        }
                        <ButtonSecondarySm onClick = { closeWhatsAppModal }><FontAwesomeIcon icon={ faTimesCircle } /> Cancelar</ButtonSecondarySm>
                        {
                            (mode === 'WhatsApp') ? 
                            <ButtonWhatsapp type="submit" disabled={isSubmitting || !condiciones} onClick={ closeWhatsAppModal } ><FontAwesomeIcon icon={ faPaperPlane } /> Enviar</ButtonWhatsapp>
                            :
                            <ButtonEmail type="submit" disabled={isSubmitting || !condiciones} ><FontAwesomeIcon icon={ faPaperPlane } /> Enviar</ButtonEmail>
                        }
                    </Form>
                }
                </Formik>
            </Modal>
            {
                products.map((item, key) => {
                    return (
                        <VoucherProductLine
                            key={key}
                            item={item}
                            handleDelete={handleDelete}
                            montaje={montaje}
                            setMontaje={setMontaje}
                            alineacion={alineacion}
                            setAlineacion={setAlineacion}
                            inflado={inflado}
                            setInflado={setInflado}
                        />
                    )
                })
            }
            </> : <ButtonPrimarySm disabled={!voucher} onClick={handleAddMore}>Buscar productos</ButtonPrimarySm>
    }
    </div>
}