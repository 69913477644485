import styled from '@emotion/styled'


//estilos comunes de botones
const commonH = `
    color: var(--primary-color);
`
/**
 * Estilos de botones de formulario
 */
export const H1 = styled.h1`
    ${commonH}
    font-size: 20px;

`
