import React, {useState, useEffect} from 'react'
import { useHistory } from 'react-router'

import {MAIN_BUTTONS} from 'config/config'

import MainCard from 'components/MainCard/MainCard'
import ModalIframe from 'components/ModalIframe/ModalIframe'

import useUser from 'hooks/useUser'

import './Main.css'

export default function Main() {
    const { logout } = useUser()
    const history = useHistory()
    const [showVideo, setShowVideo] = useState(false)
    const[show, setShow] = useState(false)
    const[modalSrc, setModalSrc] = useState('')
    const[modalTitle, setModalTitle] = useState('')
    const handleClick = (e, target, src, title) => {
        if (target === '_blank') {
            e.preventDefault()
            setModalSrc(src)
            setModalTitle(title)
            setShow(true)
        }
    }
    const closeModal = () => {
        setModalSrc("")
        setModalTitle("")
        setShow(false)
    }

    const handleOnLogout = () => {
        console.log("handle")
        logout()
    }

    /* useEffect(() => {
    const timer = setTimeout(() =>  {
            history.push("/video")
        }, 60000); 
    
        return () => clearTimeout(timer)
    }, [showVideo]) */

    return (
            <>
                <div className="Main-container">
                    <div className="Main-grid">
                        {
                            MAIN_BUTTONS.map((button, key) => 
                                <MainCard
                                    key={button.id}
                                    text={button.text}
                                    url={button.url}
                                    image={button.image}
                                    alt={button.image_alt}
                                    target={button.target}
                                    handleClick={handleClick}
                                    featured={button.featured}
                                />
                            )
                        }
                    </div>
                    <div className="Main-background"></div>
                </div>
                <div style={{color: "white", cursor: "pointer", position: "fixed", top: "0"}} onClick={() => handleOnLogout()}>Cerrar sesión</div>
                <ModalIframe src={modalSrc} title={modalTitle} show={show} closeModal={closeModal}/>
            </> 
    )
}