import React, {useState} from 'react'
import { useHistory } from 'react-router'

import { Form, Field, Formik, ErrorMessage } from 'formik'
import { initialValues, validateFields } from './FormikConfigs'

import useUser from 'hooks/useUser'

import './Login.css'

export default function Login() {
    const { login } = useUser()
    const [err, setErr] = useState(false)
    let history = useHistory()

    const handleOnSubmit = (values, {setSubmitting}) => {
        login({username: values.username, password: values.password}).then(() => {
            setErr(false)
            history.push("/")
        })
        .catch(error => {setErr(error.message)})
        setSubmitting(false)
    }
    return <Formik
        initialValues={initialValues}
        validate={validateFields}
        onSubmit={handleOnSubmit}
        validator={() => ({})}
        >
        {
            ({isSubmitting, status}) =>
                <div className="Login-container">
                    {/* <h1 className="">LOGIN</h1> */}
                    <div className="welcome">
                        <div className="welcomeText">
                            <h3>
                                BIENVENIDOS A TOP RECAMBIOS
                            </h3>
                        </div>
                        <div className="login-triangle"></div>
                        <div className="Login-block">
                            <Form>
                                <div className="Form-row">
                                    <div className="Form-col">
                                        <Field type="text" placeholder="Usuario" name="username" />
                                        <ErrorMessage name="username">
                                            {msg => <span className="tooltip-right">{msg}</span>}
                                        </ErrorMessage>
                                    </div>
                                </div>
                                <div className="Form-row">
                                    <div className="Form-col">
                                        <Field type="password" placeholder="Contraseña" name="password" />
                                        <ErrorMessage name="password">
                                            {msg => <span className="tooltip-right">{msg}</span>}
                                        </ErrorMessage>
                                    </div>
                                </div>
                                <button
                                    disabled={isSubmitting}
                                    type="submit">
                                    {isSubmitting ? 'Procesando el envío ...' : 'Continuar'}
                                </button>
                                <br/>
                                <br/>
                                { (err !== false) &&
                                    <span style={{color: "red"}}>Acceso denegado</span>
                                }
                            </Form>
                                {
                                    (status && status.success) ?
                                            <span>{status.success}</span>
                                        :
                                            <span>{(status && status.error) ? status.error : ''}</span>

                                }
                        </div>
                    </div>
                </div>
        }
        </Formik>
    
}