import React, { useState } from 'react'

import TireResultsTable from 'components/TireResultsTable/TireResultsTable';

import { tireImageExist } from 'hooks/useUtils'

import './TireResults.css'

export default function TireResults({ measure, brokerType = 'neumaticos', filters, voucher = null, handleSubmit }) {
    const [image, setImage] = useState("")
    const [focus, setFocus] = useState(false)
    return (
        <>
        <TireResultsTable measure={measure} brokerType={brokerType} filters={filters} voucher={voucher} image={image} setImage={setImage} focus={focus} setFocus={setFocus} handleSubmit={handleSubmit} />
        {
            (measure !== undefined) && 
            <div className={`big-image-container${(focus) ? ' focus' : ''}`} onClick={() => setFocus(false)}>
                <div className="big-image">
                    <img src={tireImageExist(image)} width="400" alt={image}/>
                </div>
            </div>
        }
        </>
    )
}
