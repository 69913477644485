import React, {useState, useEffect} from 'react';
import { useHistory } from "react-router-dom"

import TireFinder from 'components/TireFinder/TireFinder'
import TireResults from 'components/TireResults/TireResults'
import { IcsPageGeneralContent, Row, Col1, Col9 } from 'components/ElementsStyled/Containers'

import store from 'redux/index';
import { removeTires, reciveTires } from 'redux/actions/tires';

import { getMeasure } from 'services/tireSearch';

import './Tires.css'

export default function Tires(props) {
    let history = useHistory()

    const {width, profile, rim, filters} = props.match.params
    const w = width !== undefined ? width : ""
    const p = profile !== undefined ? profile : ""
    const r = rim !== undefined ? rim : ""

    let measure = ""
    const [medida, setMedida] = useState({medida: undefined, ancho: w, perfil: p, llanta: r})
    
    if (
        w !== undefined && w !== "" &&
        p !== undefined && p !== "" &&
        r !== undefined && r !== ""

    ) {
        getMeasure(w, p, r).then((item)  => {
            if (item === undefined) {
                //setMedida({medida: undefined, ancho: undefined, perfil: undefined, llanta: undefined})
            } else {
                if (medida.medida !== item.medida) {
                    setMedida(item)
                }
            }
        }).catch((error) => {
            console.log(error)
        })
    } else {
        store.dispatch(removeTires())
    }

    /* useEffect(() => {
        console.log(measure)
        if (measure === undefined) {
            setMedida({medida: undefined, ancho: undefined, perfil: undefined, llanta: undefined})
            store.dispatch(requestTires(false))
        } else {
            setMedida({medida: measure, ancho: undefined, perfil: undefined, llanta: undefined})
        }
    }, [measure]) */

    const handleSubmit = () => {
        const inputs = document.getElementsByTagName("input")
        for (let input of inputs) {
            if (input.type === "checkbox") {
                if (input.checked) {
                    const buttons = input.form.getElementsByTagName("button")
                    for (let button of buttons) {
                        if (button.type === "submit") {
                            button.click()
                        }
                    }
                }

            }
        }
        store.dispatch(removeTires())
        history.push("/presupuestos")
        setTimeout(() => {
            history.push("/presupuestos")
        }, 500)
    }


    return (
        <IcsPageGeneralContent>

            <Row>
                <Col1></Col1>
                <Col9>
                    <TireFinder measure={medida.medida} width={w} profile={p} rim={r} filters={filters} />
                </Col9>
                <Col1>
                </Col1>
            </Row>
            <Row>
            </Row>
            {
                (medida.medida !== undefined) && <TireResults measure={medida.medida} filters={filters} handleSubmit={handleSubmit} />
            }

        </IcsPageGeneralContent>
    )
}