import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { sortFilters } from 'config/config'

import { TireBrokerBlock } from 'components/BrokerBlock/BrokerBlock'
import { ErrorMessage } from 'components/ElementsStyled/Message'
import { ButtonPrimarySm, ButtonSecondarySm } from 'components/ElementsStyled/Button'
import TireLineTr from './TireLineTr'

import { beautifyPrice } from 'hooks/useUtils'
import { getTireFeaturesES, getTireIcons } from 'hooks/getProductFeatures'

import store from 'redux/index';
import { fetchTiresIfNeeded } from 'redux/actions/tires';

import './TireResultsTable.css'
import imagen from './images/loading.gif'
import neutral_sort_arrow from './images/neutral_sort_arrow.png'
import up_sort_arrow from './images/up_sort_arrow.png'
import down_sort_arrow from './images/down_sort_arrow.png'
//import imagen from './images/derrape.gif'

function TireResultsTable ({ measure, brokerType = 'neumaticos', filters, isLoading, tires, errorMessage, voucher = null, image, setImage, setFocus, focus, handleSubmit })
{
    const propTypes = {
        isLoading: PropTypes.bool.isRequired,
        tires: PropTypes.array.isRequired,
        errorMessage: PropTypes.string
    }

    const [products, setProducts] = useState([]);
    const [sortDireccion, setSortDireccion] = useState("ASC");
    const [sortMethod, setSortMethod] = useState("precioNeto");

    useEffect(() => {
        setProducts(tires);
    }, [tires])

    const handleSort = (sortBy) => {
        let sDir = sortDireccion;
        if (sortBy !== sortMethod) {
            sDir = "DESC";
        }
        products.sort((a,b) => {
            if (sDir === "ASC") {
                setSortDireccion("DESC")
                if (a[sortBy] > b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] < b[sortBy]) {
                    return -1;
                }
                return 0;
            } else {
                setSortDireccion("ASC")
                if (a[sortBy] < b[sortBy]) {
                    return 1;
                }
                if (a[sortBy] > b[sortBy]) {
                    return -1;
                }
                return 0;
            }
        });
        setProducts(products);
        setSortMethod(sortBy);
    }

    const search = {measure: measure, brokerType: brokerType, filters: filters}
    store.dispatch(fetchTiresIfNeeded( {search} ))
    return(
            isLoading
                ? <img className="imagenBlur" src={imagen} alt="Resultados"></img>
                : errorMessage
                    ? <ErrorMessage>{errorMessage}</ErrorMessage>
                    :
                    (tires !== undefined && tires !== null && tires.length > 0) ?
                    <>
                    <div className='TireResults-orderLine'>
                        <b>Ordenar por:</b>
                        {
                            sortFilters.map((item, key) => {
                                return (sortMethod !== item.field) ?
                                    <ButtonSecondarySm key={`Sort-${key}`} className='TireResults-sort' onClick={() => handleSort(item.field)}>
                                        {item.name} <img src={neutral_sort_arrow} width="16" align="top" alt=""></img>
                                    </ButtonSecondarySm>
                                :
                                    <ButtonPrimarySm key={`Sort-${key}`} className='TireResults-sort' onClick={() => handleSort(item.field)}>
                                        {item.name} {(sortDireccion === "DESC") ? 
                                            <img src={up_sort_arrow} width="16" align="top" alt=""></img> 
                                            : <img src={down_sort_arrow} width="16" align="top" alt=""></img> }
                                    </ButtonPrimarySm>
                                }
                            )
                        }
                    </div>
                    <table className="TireResults-table">
                        <tbody>
                            {
                                products.map((tire) =>
                                    <TireLineTr
                                        id={tire.id}
                                        brandName={tire.m_nombre}
                                        reference={tire.referencia}
                                        stockView={(tire.stockTotal < 12) ? tire.stockTotal : "+12"}
                                        image={tire.imagen}
                                        name={tire.nombre}
                                        icons={getTireIcons(tire)}
                                        features={getTireFeaturesES(tire)}
                                        road={tire.carretera ? tire.carretera : null}
                                        field={tire.campo ? tire.campo : null}
                                        charge={tire.carga}
                                        speed={tire.velocidad}
                                        chargeSpeed={tire.carga + tire.velocidad}
                                        runflat={tire.runflat}
                                        vehicleType={tire.tipo_vehiculo}
                                        season={tire.tipo_neumatico}
                                        consumption={(tire.consumo) ? tire.consumo.toLowerCase() : null}
                                        braking={(tire.frenada) ? tire.frenada.toLowerCase() : null}
                                        sonorityLevel={(tire.nivel_sonoridad) ? tire.nivel_sonoridad.toLowerCase() : null}
                                        sonority={tire.sonoridad}
                                        pvp={beautifyPrice(tire.pvp)}
                                        netPrice={beautifyPrice(tire.precioNeto)}
                                        tireBrokerBlock={(false && brokerType !== "broker50" && brokerType !== "broker300") && <TireBrokerBlock broker50={tire.tarifa6} broker300={tire.precioNetoTarifa7}></TireBrokerBlock>}
                                        eprel={tire.id_eprel}
                                        tire={tire}
                                        setImage={setImage} focus={focus} setFocus={setFocus} focusImage={image}
                                    />
                                )
                            }
                        </tbody>
                    </table>
                    <ButtonPrimarySm id="AddAllToCart" onClick={handleSubmit}>Obtener Presupuesto</ButtonPrimarySm>

                    </>
                    :
                    (tires !== undefined && measure !== undefined) ? <div>
                    <h3 style={{
                        "marginTop": "100px",
                        "padding": "20px",
                        "border": "1px solid red",
                        "border-radius": "8px",
                        "display": "inline-block",
                        "background-color": "rgba(255, 0, 0, 0.2)"
                }}>No se ha encontrado ninguna referencia</h3>
                </div>
                        
                : null
        
    )
}

const mapStateToProps = (state, ownProps) => {
    let {isLoading, tires, errorMessage} = state.tires
    let {measure, brokerType, filters, voucher} = ownProps

    return {
        isLoading,
        tires,
        errorMessage,
        measure,
        brokerType,
        filters,
        voucher
    }
}

export default connect(mapStateToProps) (TireResultsTable)