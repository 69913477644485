import React from 'react'
import './Modal.css'

const Modal = ({ isOpen, closeModal, title, isCart, children, className }) => {

    const handleModalDialogClick = (e) => {
        e.stopPropagation()
    }
   /* {(tire.nivel_sonoridad) ? tire.nivel_sonoridad.toLowerCase() : null} */
    return (
        <div className = {`modal ${ isOpen && 'modal-open' } ${className === 'top' && ' top'}`} onClick = { closeModal }>
            <div className =  {(isCart) ? 'modal_cart': 'modal__dialog'} onClick = { handleModalDialogClick }>
                <h2>{ title }</h2>
                {/* <button onClick = { closeModal }>Cerrar</button> */}

                { children }
            </div>
        </div>
    )
}

export default Modal