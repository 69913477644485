
/**
 * Valores iniciales del formulario de registro
 */
export const initialValues = (id) => {
    
    return {
        id: id || 0,
        quantity: 1
    }
}
/**
 * Metodo que maneja la validacion del formulario
 */
export function validateFields(values) {
    const errors = {}
    if (!values.id) {
        errors.id = 'Campo obligatorio'
    }
    if (!values.quantity) {
        errors.quantity = 'Mínimo 1 unidad'
    }
    return errors
}