import styled from '@emotion/styled'

import {
    Field as FieldFormik
} from 'formik'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//Estilos comunes de divs de controles
const commonFormControl = `
    border-bottom: 1px solid lightgray;
    display: flex;
    margin: 5px 15px;
    padding: 0 12px;
`
/**
 * Estilos de contenedores de inputs
 */
export const FormControlCheck = styled.div`
    ${commonFormControl}
    border: 0;
`
export const FormControl = styled.div`
    ${commonFormControl}
    margin-bottom: 0px;
`

/**
 * Estilos de etiquetas de inputs
 */
export const InputLabel = styled.label`
    flex: auto;
    display: flex;
    align-items: center;
    color: var(--light-secondary-color);
    flex-grow: 1;
    font-size: 15px;
`
export const CheckLabel = styled.label`
    font-size: 15px;
    display: flex;
    align-items:center;
    position: relative;
    cursor: pointer;
    color: black;
`

/**
 * Estilos de imagenes de inputs
 */
export const Svg = styled(FontAwesomeIcon)`
    font-size: 15px;
    padding-right: 10px;
    color: var(--light-secondary-color);
`

/**
 * Estilos de campos de formulario
 */
export const Field = styled(FieldFormik)`
    flex-grow: 9;
    min-height: 30px;
    border: none;
    ::placeholder {
        color: var(--secondary-color);
        font-size: 15px;
    }
`
export const FieldCheckbox = styled(FieldFormik)`
    height: 0;
    opacity: 0;
    width: 0;
`

export const alertRed = styled.div`
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
`