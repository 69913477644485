import React, { useContext, useState, useEffect } from 'react'

import { WEB_URL } from 'config/defines';

import { Row } from 'components/ElementsStyled/Containers'
import VoucherProducts from 'components/VoucherProducts/VoucherProducts';

import Context from 'context/UserContext'

import store from 'redux/index';
import { setVoucher } from 'redux/actions/voucher';

import { create } from 'services/voucher'

import './VoucherResume.css'

export default function VoucherResume() {
    const {userData} = useContext(Context)
    const [process, setProcess] = useState(false)
    const [rotate, setRotate] = useState(false)
    const [canConfirm, setCanConfirm] = useState(false)

    const handleFinish = () => {
        const id_poblacion = userData.direccion.id
        setProcess(true)
        setTimeout(() => {
            setProcess(false)
        }, 3000)

        create({
            user: userData,
            voucher: store.getState().voucher.voucher
        }).then(item => {
            return fetch(`${WEB_URL}/presupuesto_tienda_pdf_particular.php?id=${item.id}&id_usuario=${userData.id}&id_poblacion=${id_poblacion}&username=${userData.username}&send=1&particular=1`).then(response => {
                return response
            })
        })
        .catch((err) => {
            console.log(err)
        })
        .then((response) => {
            const voucher = {
                "pais": "",
                "id_pais": "",
                "provincia":"",
                "id_provincia": "",
                "codigo_postal": "",
                "poblacion": "",
                "id_poblacion": "",
                "razon_social": "",
                "cif": "",
                "direccion": "",
                "telefono": "",
                "email": "",
                "items": [],
            }
            store.dispatch(setVoucher(voucher))
            setCanConfirm(false)
        }).then(() => {
            /**
             * @TODO Popup de confirmación y redirect a los 2 segundos
             */
            //window.location.reload();
        })
    }

    useEffect(() => {
        setRotate(process)
    }, [process])

    return(
        <div className='VoucherResume-container'>
            <Row>
                { (process === true) ?
                    <div className="confirmation">
                        <div className={(rotate) ? "rotate" : null}>
                        ✔
                        </div>
                    </div>
                    :
                    null
                }
                    {/* <VoucherForm redirect={(store.getState().voucher.voucher === false)} setCanConfirm={setCanConfirm}/> */}
                        {
                        (store.getState().voucher.voucher) ? 
                            <VoucherProducts
                                canConfirm={canConfirm}
                                products={store.getState().voucher.voucher.items}
                                voucher={store.getState().voucher.voucher}
                                handleFinish={handleFinish}
                            /> : null
                        }
            </Row>
        </div>
    )
}
