export function getTireFeatures(product) {

    const lines = []
    if (product) {
        
        if(product.rimProtection == true) {
            lines.push("Con proteccion de llanta");
        }
        if(product.selfSealing == 1){
            lines.push("Autosellante");
        }
        if(product.whiteLetters == 1){
            lines.push("Letras blancas");
        }
        if(product.antiNoiseFoam == 1){
            lines.push("Espuma antiruido");
        }
        if(product.whiteBand == 1){
            lines.push("Banda blanca");
        }
    }

    return lines
}

export function getTireFeaturesES(product) {

    const lines = []

    if (product) {
        
        if(product.proteccion_llanta === '1') {
            lines.push("Con proteccion de llanta");
        }
        /* if(product.autosellante === "1"){
            lines.push("Autosellante");
        } */
        if(product.letras_blancas === "1"){
            lines.push("Letras blancas");
        }
        if(product.espuma_antiruido === "1"){
            lines.push("Espuma antiruido");
        }
        if(product.banda_blanca === "1"){
            lines.push("Banda blanca");
        }
        if(product.descripcion_extra !== null){
            lines.push(product.descripcion_extra);
        }
    }

    return lines
}

export function getTireIcons(tire) {

    const lines = []

    if (tire) {
        if ((tire.stock_tienda !== undefined && tire.stock_tienda !== null && tire.stock_tienda !== "0")) {
            lines.push({
                name: "🟢",
                title: "Disponible en tienda",
                className: ""
            })
        }
        if (tire.idTireSeason && tire.idTireSeason !== "1") {
            lines.push({
                name: "Temporada",
                src: "/images/icons/prod-list/tyre_type_" + tire.season.code + ".png",
                title: tire.season.name,
                className: ""
            })
        }
        if (tire.threePeaksMountain && tire.threePeaksMountain !== "0") {
            lines.push({
                name: "3 picos montaña",
                src: "/images/icons/prod-list/3pm.png",
                title: "Neumático homologado para circular sobre hielo y nieve",
                className: "3pm"
            })
        }
        if (tire.ms && tire.ms !== "0") {
            lines.push({
                name: "(M+S)",
                title: "Neumático con características invernales",
                className: "MS"
            })
        }
        if (tire.por && tire.por !== "0") {
            lines.push({
                name: "(P.O.R.)",
                title: "Esta homologación excluye al neumático de estar certificado con etiquetado energético",
                className: "por"
            })
        }
    }

    return lines
}
