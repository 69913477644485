import React, {useEffect} from 'react';

import { Row } from 'components/ElementsStyled/Containers';

import './Gratitude.css';
import { ButtonPrimarySm } from 'components/ElementsStyled/Button';

export default function Gratitude(props)
{
    const {data} = props.match.params
    const userInfo = JSON.parse(atob(data));

    useEffect(() => {
        const timer = setTimeout(() =>  {
            window.location.reload();
        }, 30000); 
      
          return () => clearTimeout(timer)
    }, [])

    return(
        <div className="Gratitude-background">
            <div className="Gratitude-container">
                <Row>
                    <div className="Gratitude-content">
                        <h1>¡GRACIAS {userInfo.nombre.toUpperCase()}!</h1>
                        <hr />
                        <h3>Nos alegramos de que hayas pensado en nosotros para tu cambio de neumáticos.</h3>
                        {
                            (userInfo.mode === "WhatsApp") ?
                            <>
                                <p>Se ha enviado el presupuesto por WhatsApp al número <span className="text-blue text-bold">{userInfo.telefono}</span>.</p>
                            </>
                            :
                            <>
                                <p>Se ha enviado el presupuesto a la direccion de correo <span className="text-blue text-bold">{userInfo.email}</span>.</p>
                                <p>Si no puede ver el correo revise la carpeta de spam.</p>
                            </>
                        }
                        <br/>
                        <p>Para cualquier consulta llame al <span className="text-blue text-bold">963 79 04 03</span>.</p>

                        <br/><br/><br/>
                        <ButtonPrimarySm onClick={() => {window.location.reload();}}>Finalizar</ButtonPrimarySm>
                    </div>
                </Row>
            </div>
        </div>
    )
}