import React, {useContext, useEffect} from 'react'
import {Route as ReactRoute} from 'react-router-dom'

import Route from './components/ProtectedRoute/ProtectedRoute'
import Header from './components/Header/Header'
import GoUp from 'components/GoUp/GoUp';

import Context from './context/UserContext'

import Main from './pages/Main/Main';
import VoucherResume from './pages/VoucherResume/VoucherResume';
import Login from './pages/Login/Login';
import Tires from './pages/Tires/Tires';
import TireDetail from './pages/Tires/TireDetail';
import Privacy from './pages/Privacy/Privacy';
import Gratitude from 'pages/Gratitude/Gratitude';

import store from 'redux/index';
import { setVoucher } from 'redux/actions/voucher';

import './App.css';
import Video from 'pages/Video/Video';

function App() {
  const {userData} = useContext(Context)

  useEffect(() => {
      const voucher = {
        "pais": "",
        "id_pais": "",
        "provincia":"",
        "id_provincia": "",
        "codigo_postal": "",
        "poblacion": "",
        "id_poblacion": "",
        "razon_social": "",
        "cif": "",
        "direccion": "",
        "telefono": "",
        "email": "",
        "items": [],
    }
    store.dispatch(setVoucher(voucher))
  }, [])

  return (
    <div className="App">
        <Header />
        <div className="App-container">
          <Route exact component={VoucherResume} path="/presupuestos" usuario={userData} />
          <Route exact component={Tires} path="/neumaticos/:width?/:profile?/:rim?/:filters?" usuario={userData} />
          <Route exact component={Privacy} path="/politica-privacidad" usuario={userData} />
          <Route exact component={Gratitude} path="/presupuesto-enviado/:data" usuario={userData} />
          <Route exact component={Video} path="/video" usuario={userData} />
          <ReactRoute exact component={TireDetail} path="/neumatico/:id" usuario={userData} />
          {(userData === null) ?
            <ReactRoute exact path="/" component={Login} />
            :
            <Route exact path="/" component={Main}/>
          }
        </div>
        <GoUp />
    </div>
  );
}

export default App;
