import React from 'react'

import { Row, Col1 } from 'components/ElementsStyled/Containers'
import { ButtonPrimarySm, /* ButtonSecondarySm */ } from 'components/ElementsStyled/Button'

/* import Cart from 'components/Cart/Cart' */

export default function ConfirmRow({ isSubmitting, correctMeasure, handleContinue, handleSubmit })
{
    return (
    <>
        <Row>
            {/* <Col1></Col1> */}
            <Col1>
                {/* <ButtonSecondarySm
                onClick={handleContinue}>
                    Confirmar
                </ButtonSecondarySm> */}
            </Col1>
            <Col1>
                <ButtonPrimarySm
                    disabled={isSubmitting || !correctMeasure}
                    type="submit"
                    className="TireFinder-submit">
                    Buscar
                </ButtonPrimarySm>
            </Col1>
            <Col1>
                {/* <Cart
                handleContinue={handleContinue} /> */}
            </Col1>

        </Row>
    </>
    )
}