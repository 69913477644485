import React from 'react'
import { Link } from 'react-router-dom'
import { Img } from 'react-image';

import { AddToCartCheckbox } from 'components/AddToCart/AddToCart';

import { tireImageExist, brandImageExist, getTireImageUrl, getBrandImageUrl } from 'hooks/useUtils'

import './TireResultsTable.css'
import broken from './images/o/broken.png'
import brokenBrand from './images/o/broken_brand.png'

export default function TireLineTr (props)
{
    const handleImageClick = () => {
        if (props.focusImage !== props.image) {
            props.setImage(props.image)
            props.setFocus(true)
        } else {
            props.setFocus(!props.focus)
        }
    }
    return (
        <tr key={props.key}>
        <td className="TireResults-image" onClick={handleImageClick}>
        <Img
            width="60"
            src={[getTireImageUrl(props.image)]}
            loader={<Img src={broken} />}
            unloader={<Img src={broken} />}
            crossorigin="https://top-tactil.desarrollogrupoics.com"
        />
        <Img
            width="80"
            src={[getBrandImageUrl(props.brandName + ".jpg")]}
            loader={<Img src={brokenBrand} />}
            unloader={<Img src={brokenBrand} />}
            crossorigin="https://top-tactil.desarrollogrupoics.com"
        />
        {/* <img src={tireImageExist(props.image)} width="60" alt={props.name}/> */}
        {/* <img src={brandImageExist(props.brandName + ".jpg")} width="80" alt={props.brandName}/> */}<br></br>
        </td>
        <td className="TireResults-description">
            <div className="TireResults-descriptionContent">
                {
                    props.icons.map((feature, key) =>
                        (feature.src) ? <img key={key} alt={feature.title} src={feature.src} width="25" title={feature.title} />
                            : <div key={key} className={feature.className} title={feature.title} >{feature.name}</div>
                    )
                }
            </div>
            <p className="link TireResults-name"><strong>{props.name}</strong></p>
            {
                props.features.map((feature, key) => <><span key={key}>{feature}</span><br></br></>)
            }
          {/* <span>{props.brandName}</span><br></br> */}
            {/* <small>Ref: {props.reference}</small> */}
        </td>
        <td className="TireResults-road">
    {
        (props.road !== null && props.field !== null) ?
        <>
            Carretera: <span>{props.road}%</span><br/>
            Campo: <span>{props.field}%</span>
            </> : null
    
    }
    </td>

        <td className="TireResults-chargeSpeed">
            { props.chargeSpeed }
        </td>
        <td className="TireResults-runflat">
            {
            (props.runflat === "1") &&
                    <>
                    <div className="bg-icon bg-runflat"></div>
                    <br></br><small>Runflat</small>
                    </>
            }
        </td>
        <td className="TireResults-detail">
            <Link to={"/neumatico/" + props.id}>
                <div className="link bg-icon bg-camera" title="Detalle"></div>
            </Link>
        </td>
        <td className="TireResults-vehicleType">
            <>
                <div className={"bg-icon bg-vehicle_type_" + props.vehicleType.codigo} alt={props.vehicleType.nombre}></div>
                <br></br>
                <div className={"bg-icon bg-tyre_type_" + props.season.codigo} alt={props.season.nombre}></div>
            </>
        </td>
        <td className="TireResults-efficiency">
            <>
            { (props.consumption !== null) &&
                <>
                    <div className="bg-icon bg-eficiencia_consumo"></div>
                    <div className={"bg-icon bg-eficiencia_" + props.consumption}></div>
                    <br></br>
                </>
            }
            { (props.braking !== null) &&
                <>
                    <div className="bg-icon bg-eficiencia_frenada"></div>
                    <div className={"bg-icon bg-eficiencia_" + props.braking}></div>
                    <br></br>
                </>
            }
            { (props.sonorityLevel !== null && props.sonority !== null) &&
                <>
                    <div className="bg-icon bg-eficiencia_sonoridad"></div>
                    {
                        (props.sonorityLevel) ? <div className={`bg-icon bg-eficiencia_sonoridad${(props.eprel !== "0") ? "_nueva" : ""}_${props.sonorityLevel}`}></div> : ''
                    }
                    <span>{props.sonority}dB</span>
                    <br></br>
                </>
            }

                <strong style={{ fontSize: "0.8em",  }}>{ (props.eprel !== "0") ? "Normativa 2020/740" : "Normativa 1222/2009" }</strong>
            </>
        </td>
        <td className="TireResults-stock">
            {props.stockView}
            <br></br><small>Stock</small>
            
        </td>
        {/* <td className="TireResults-pvp">
            {props.pvp}
            <br></br><small>PVP</small>
        </td> */}
        <td className="TireResults-netPrice" width="110px">
            <strong>{props.netPrice}</strong>
            <br></br><small>Neto</small>
            <br></br>
            {props.tireBrokerBlock}
        </td>
        {/* <td className="TireResults-delivery">
            24 <br></br><small>Horas</small>
        </td> */}
        <td className="TireResults-addCart">
            <AddToCartCheckbox item={props.tire} />
        </td>
    </tr>
    )
}

/* function TireLineImage({url}) {
    const {src} = useImage({
        imgPromise: url,
    })
    
    return <img src={src} alt="Imagen de la rueda" />
} */