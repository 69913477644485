import fileExists, {tireImageExists, brandImageExists, getTireImagesUrl, getBrandImagesUrl} from './fileExist'
import beautifyPrices from './beautifyPrice'

export function fileExist(urlToFile) {
    return fileExists(urlToFile);
}

export function tireImageExist(urlToFile) {
    return tireImageExists(urlToFile)
}

export function getTireImageUrl(urlToFile) {
    return getTireImagesUrl(urlToFile)
}

export function getBrandImageUrl(urlToFile) {
    return getBrandImagesUrl(urlToFile)
}

export function brandImageExist(urlToFile) {
    return brandImageExists(urlToFile)
}

export function beautifyPrice (price) {
    return beautifyPrices(price)
}

export function isEquivalent(first, second) {
  
    // If the value of either variable is empty
    // we can instantly compare them and check
    // for equality.
    if (
      first === null ||
      first === undefined ||
      second === null ||
      second === undefined
    ) {
      return first === second;
    }
  
    // If neither are empty, we can check if
    // their constructors are equal. Because
    // constructors are objects, if they are
    // equal, we know the objects are of the
    // same type (though not necessarily of
    // the same value).
    if (first.constructor !== second.constructor) {
      return false;
    }
  
    // If we reach this point, we know both
    // objects are of the same type so all
    // we need to do is check what type one
    // of the objects is, and then compare
    // them
    if (first instanceof Function || first instanceof RegExp) {
      return first === second;
    }
  
    // Throught back to the equlity check
    // we started with. Just incase we are
    // comparing simple objects.
    if (first === second || first.valueOf() === second.valueOf()) {
      return true;
    }
  
    // If the value of check we saw above
    // failed and the objects are Dates,
    // we know they are not Dates because
    // Dates would have equal valueOf()
    // values.
    if (first instanceof Date) return false;
  
    // If the objects are arrays, we know
    // they are not equal if their lengths
    // are not the same.
    if (Array.isArray(first) && first.length !== second.length) {
      return false;
    }
  
    // If we have gotten to this point, we
    // need to just make sure that we are
    // working with objects so that we can
    // do a recursive check of the keys and
    // values.
    if (!(first instanceof Object) || !(second instanceof Object)) {
      return false;
    }
  
    // We now need to do a recursive check
    // on all children of the object to
    // make sure they are deeply equal
    const firstKeys = Object.keys(first);
  
    // Here we just make sure that all the
    // object keys on this level of the
    // object are the same.
    const allKeysExist = Object.keys(second).every(
      i => firstKeys.indexOf(i) !== -1
    );
  
    // Finally, we pass all the values of our
    // of each object into this function to
    // make sure everything matches
    const allKeyValuesMatch = firstKeys.every(i =>
        isEquivalent(first[i], second[i])
    );
  
    return allKeysExist && allKeyValuesMatch;
  }

  export function arraySearchId(array, id) {
      return array.find(
          function (element) {
              const match = element.id == id

              return match 
          }
      )
  }

  export function arraySearchIdKey(array, id) {
      return array.findIndex(
          function (element) {
              const match = element.id == id

              return match 
          }
      )
  }

  export function arraySearchNumeroKey(array, id) {
      return array.findIndex(
          function (element) {
              const match = element.numero == id

              return match 
          }
      )
  }

  export function arraySearchNombreKey(array, nombre) {
      return array.find(
          function (element) {
              const match = element.nombre == nombre

              return match 
          }
      )
  }

    /**
     * Función para sacar los campos GET de la URL
     */
  export function formatFilters(filters) {
    let objectFilters = {}
    if (filters !== undefined) {
      filters.split('&').forEach((filter) => {
          var [key, value] = filter.split('=')
          if (value === "HT") {
            value = "H/T";
          } else if (value === "MT") {
            value = "M/T";
          } else if (value === "AT") {
            value = "A/T";
          }
          Object.assign(objectFilters, {[key]: value})
      })
    }
    return objectFilters
  }

  //función para usar en el "sort" para ordenar de mayor a menor
  export function hightLess ( a, b ) {
    if ( a.precioNeto > b.precioNeto ){
      return -1;
    }
    if ( a.precioNeto < b.precioNeto ){
      return 1;
    }
    return 0;
  }

  //función para usar en el "sort" para ordenar de mayor a menor
  export function lessHight ( a, b ) {
    if ( a.precioNeto < b.precioNeto ){
      return -1;
    }
    if ( a.precioNeto > b.precioNeto ){
      return 1;
    }
    return 0;
  }