import React, {useState, useRef, useEffect} from 'react';

import { Formik } from 'formik';

import { ButtonSecondaryXSm } from 'components/ElementsStyled/Button'
import { Form, Row, Col2 } from 'components/ElementsStyled/Containers'
import { initialValues, validateFields } from 'components/AddToCart/FormikConfigs'

import store from 'redux/index';
import { changeQuantity } from 'redux/actions/tires';
import { addVoucherItem, } from 'redux/actions/voucher';


import './AddToCart.css'
import { InputNumber } from 'components/ElementsStyled/Input';

export default function AddToCart({item}) {

    const [quantity, setQuantity] = useState('')
    const [submitting, setSubmitting] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const handleOnChange = (event) => {
        setQuantity(event.target.value)
    }

    const handleOnSubmit = async (values) => {
        setSubmitting(true)
        setSubmitted(false)
        item.quantity = parseInt(quantity)
        store.dispatch(addVoucherItem(item))
        setTimeout(() => {
                setSubmitting(false)
                setSubmitted(true)
            }, 1000
        )
        setTimeout(() => {
                setSubmitted(false)
                setQuantity('')
            }, 3000
        )
    }
    const handleQuantity = (e) => {
        let newQuantity

        if (e.target.value === "add") {
            newQuantity = (quantity !== "") ? parseInt(quantity) + 1 : 1
            if (newQuantity > item.stockTotal) {
                newQuantity = item.stockTotal
            }

        } else if (e.target.value === "remove") {
            newQuantity = (quantity !== "") ? parseInt(quantity) - 1 : ""
            if (newQuantity < 1) {
                newQuantity = 1
            }
        }
        setQuantity(newQuantity)
    }
    return <Formik
        initialValues={initialValues(item.id)}
        validate={validateFields}
        onSubmit={handleOnSubmit}
    >
        {
            () =>
            <div className="AddToCart-container">
                <Form>
                        <input type="hidden" name="id" value={item.id}></input>
                        <Row>
                            <Col2>
                                <input
                                    name="quantity"
                                    value={quantity}
                                    onChange={handleOnChange}
                                    placeholder=""
                                    type="number"
                                    min="1"
                                    max={item.stockTotal}
                                    readOnly
                                />
                                <ButtonSecondaryXSm
                                    disabled={(submitting === true || submitted === true || quantity === '')}
                                    className={submitted ? 'submitted' : null}
                                    type="submit">
                                    {(submitting) ? '...' : (submitted) ? `✔` : 'OK' }
                                </ButtonSecondaryXSm>
                            </Col2>
                            <Col2>
                                <button className="quantity-button" onClick={(e) => {e.preventDefault();handleQuantity(e)}} value="add">+</button>
                                <button className="quantity-button" onClick={(e) => {e.preventDefault();handleQuantity(e)}} value="remove">-</button>
                            </Col2>
                        </Row>

                </Form>
            </div>
        }
    </Formik>
}

export function AddToCartHorizontal({item}) {

    const [quantity, setQuantity] = useState('')
    const [submitting, setSubmitting] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const handleOnChange = (event) => {
        setQuantity(event.target.value)
    }

    const handleOnSubmit = async (values) => {
        setSubmitting(true)
        setSubmitted(false)
        item.quantity = parseInt(quantity)
        store.dispatch(addVoucherItem(item))
        setTimeout(() => {
                setSubmitting(false)
                setSubmitted(true)
            }, 1000
        )
        setTimeout(() => {
                setSubmitted(false)
                setQuantity('')
            }, 3000
        )
    }
    const handleQuantity = (e) => {
        let newQuantity

        if (e.target.value === "add") {
            newQuantity = (quantity !== "") ? parseInt(quantity) + 1 : 1
            if (newQuantity > item.stockTotal) {
                newQuantity = item.stockTotal
            }

        } else if (e.target.value === "remove") {
            newQuantity = (quantity !== "") ? parseInt(quantity) - 1 : ""
            if (newQuantity < 1) {
                newQuantity = 1
            }
        }
        setQuantity(newQuantity)
    }
    return <Formik
        initialValues={initialValues(item.id)}
        validate={validateFields}
        onSubmit={handleOnSubmit}
    >
        {
            () =>
            <div className="AddToCart-container horizontal">
                <Form>
                        <input type="hidden" name="id" value={item.id}></input>
                        <Row>
                            <button className="quantity-button" onClick={(e) => {e.preventDefault();handleQuantity(e)}} value="remove">-</button>
                            <input
                                name="quantity"
                                value={quantity}
                                onChange={handleOnChange}
                                placeholder=""
                                type="number"
                                min="1"
                                max={item.stockTotal}
                                readOnly
                            />
                            <button className="quantity-button" onClick={(e) => {e.preventDefault();handleQuantity(e)}} value="add">+</button>
                            <ButtonSecondaryXSm
                                disabled={(submitting === true || submitted === true || quantity === '')}
                                className={submitted ? 'submitted' : null}
                                type="submit">
                                {(submitting) ? '...' : (submitted) ? `✔` : 'OK' }
                            </ButtonSecondaryXSm>
                        </Row>

                </Form>
            </div>
        }
    </Formik>
}

export function AddToCartCheckbox({item}) {
    const [quantity, setQuantity] = useState(parseInt(item.stockTotal) > 4 ? 4 : item.stockTotal)
    const [submitting, setSubmitting] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const checkbox = useRef(null)

    useEffect(() => store.subscribe(() => {
        if(!checkbox.current.checked && store.getState().tires.quantity <= item.stockTotal) {
            setQuantity(store.getState().tires.quantity)
        }
    }), []);

    const handleOnChange = (q) => {
        setQuantity(q);
    }

    const handleOnSubmit = async (values) => {
        setSubmitting(true)
        setSubmitted(false)
        item.quantity = parseInt(quantity)
        store.dispatch(addVoucherItem(item))
        setSubmitting(false)
        setSubmitted(true)
        setSubmitted(false)
        setQuantity('')
        checkbox.current.click()
        store.dispatch(changeQuantity(4))
    }

    const handleQuantity = (e) => {
        let newQuantity

        if (e.target.value === "add") {
            newQuantity = (quantity !== "") ? parseInt(quantity) + 1 : 4
            if (newQuantity > item.stockTotal) {
                newQuantity = item.stockTotal
            }

        } else if (e.target.value === "remove") {
            newQuantity = (quantity !== "") ? parseInt(quantity) - 1 : ""
            if (newQuantity < 1) {
                newQuantity = 1
            }
        }

        if (!checkbox.current.checked) {
            checkbox.current.click()
        }
        setQuantity(newQuantity)
        store.dispatch(changeQuantity( newQuantity ))
    }
    return <Formik
        initialValues={initialValues(item.id)}
        validate={validateFields}
        onSubmit={handleOnSubmit}
    >
        {
            () =>
            <div className="AddToCart-container">
                <Form>
                        <input type="hidden" name="id" value={item.id}></input>
                        <Row>
                            <Col2>
                                <input
                                    className="AddToCart-quantity"
                                    name="quantity"
                                    value={quantity}
                                    onChange={handleOnChange}
                                    placeholder=""
                                    type="number"
                                    min="1"
                                    max={item.stockTotal}
                                    readOnly
                                />
                                <label className="control control-checkbox" style={{marginLeft: 0}}>
                                    <input ref={checkbox} type="checkbox" name="product" value={item.id} style={{marginLeft: 0}}></input>
                                    <div className="control_indicator" style={{marginLeft: "7px", marginTop: "6px"}}></div>
                                </label>
                            </Col2>
                            <Col2>
                                <button type="button" className="quantity-button" onClick={(e) => {e.preventDefault();handleQuantity(e)}} value="add">+</button>
                                <button type="button" className="quantity-button" onClick={(e) => {e.preventDefault();handleQuantity(e)}} value="remove">-</button>
                            </Col2>
                            <button type="submit" style={{display: "none"}}>🛒</button>
                        </Row>

                </Form>
            </div>
        }
    </Formik>
}