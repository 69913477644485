
/**
 * 
 * @param {float} price 
 * @param {int} decimals 
 * 
 * @return {string}
 * 
 * Recibe un float y el número de decimales y devuelve una cadena con el float con ese número de decimales y la moneda
 */
export default function beautifyPrice (price, decimals = 2) {
    return (parseFloat(price).toFixed(decimals) + "€").replace(".", ",")
}