
/**
 * Valores iniciales del formulario de registro
 */
export const initialValues = (measure, width, profile, rim, brokerType, charge, velocity, brand, filter_seasson, filter_runflat, filter_selfSealing, filter_antiNoiseFoam, filter_road) => {

    const x = {
        measure: decodeURI(measure || ''),
        brokerType: brokerType || 'neumaticos',
    }
    if (width && width !== '') {
        x.width = width
    }
    if (profile && profile !== '') {
        x.profile = profile
    }
    if (rim && rim !== '') {
        x.rim = rim
    }
    /* if (charge && charge !== '') {
        x.charge = charge
    } */
    x.charge = charge
    /* if (velocity && velocity !== '') {
        x.velocity = velocity
    } */
    x.velocity = velocity
    /* if (brand && brand !== '') {
        x.brand = brand
    } */
    x.brand = brand
    if (filter_seasson && filter_seasson !== '') {
        x.filter_seasson = filter_seasson
    }
    if (filter_runflat && filter_runflat !== '') {
        x.filter_runflat = filter_runflat
    }
    if (filter_selfSealing && filter_selfSealing !== '') {
        x.filter_selfSealing = filter_selfSealing
    }
    if (filter_antiNoiseFoam && filter_antiNoiseFoam !== '') {
        x.filter_antiNoiseFoam = filter_antiNoiseFoam
    }
    if (filter_road && filter_road !== '') {
        x.filter_road = filter_road
    }

    return x
}
/**
 * Metodo que maneja la validacion del formulario
 */
export function validateFields(values) {
    const errors = {}
    if (!values.width) {
        errors.measure = 'Campo obligatorio'
    }
    if (!values.profile) {
        errors.measure = 'Campo obligatorio'
    }
    if (!values.rim) {
        errors.measure = 'Campo obligatorio'
    }
    if (!values.brokerType) {
        errors.brokerType = 'Campo obligatorio'
    }
    return errors
}