import React, {useState, useEffect, useRef} from 'react'
import { useHistory } from 'react-router'
import ReactPlayer from 'react-player'

import './Video.css'
export default function Video()
{
    const history = useHistory()
    const handleClick = () => {
        history.goBack()
    }
    return (
        <div className="Video-container">
            <ReactPlayer
                className="Video"
                url="/video-login.mp4"
                playing
                muted
                loop
                onClick={handleClick}
            />
        </div>
    )
}