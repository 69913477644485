
import {arraySearchIdKey} from 'hooks/useUtils'

export const Voucher = (state = {
    voucher: false,
    errorMessage: ''
  }, action) => {
    var key;
      switch(action.type) {
      case 'SAVE_VOUCHER':
          return {...state};
      case 'SET_VOUCHER':
          state.voucher = action.voucher
          state.errorMessage = action.errorMessage
          return {...state};
        case 'ADD_VOUCHER_ITEM':
          key = arraySearchIdKey(state.voucher.items, action.item.id)
          if (key < 0) {
            state.voucher.items.push(action.item)
          } else {
            let quantity = state.voucher.items[key].quantity + action.item.quantity
            state.voucher.items[key].quantity = quantity
          }
            state.errorMessage = action.errorMessage

            return {...state};
        case 'DELETE_VOUCHER_ITEM':
          key = arraySearchIdKey(state.voucher.items, action.id)
          if (key >= 0) {
            state.voucher.items.splice(key, 1)
          }

          state.errorMessage = action.errorMessage

          return {...state};
        case 'UPDATE_VOUCHER_ITEM':
          key = arraySearchIdKey(state.voucher.items, action.id)
          if (key >= 0) {
            state.voucher.items[key].hasMontaje = action.montaje
            state.voucher.items[key].hasAlineacion = action.alineacion
            state.voucher.items[key].hasInflado = action.inflado
            state.voucher.items[key].quantity = action.quantity
          }

          state.errorMessage = action.errorMessage

          return {...state};
          case 'UPDATE_ITEM_CART':
            key = arraySearchIdKey(state.voucher.itmes, action.id)
            if (key >= 0){
              state.voucher.items[key].quantity = action.quantity
            }
            state.errorMessage = action.errorMessage
            return {...state}
        default:
          return state;
      }
    }
     
    export default Voucher;