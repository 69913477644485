
import { API_URI } from "config/defines";

export const API_KEY = "pruebaApiEnHeaderWeb"

export default function sendRequest(uri, requestOptions, API_URL = false) {
    const api = (API_URL !== false) ? API_URL + "/api/api.php" : API_URI;
    const headers = requestOptions.headers ?? new Headers();
    //headers.append("ICS-API-KEY", API_KEY);
    headers.append("Content-Type", "application/json");

    const requestOptionsGlobal = {
        headers: headers
    };

    requestOptions = Object.assign(requestOptions, requestOptionsGlobal)
    return fetch(api + uri, requestOptions)
        .then(res => {
            return res.json()
        })
        .then(res => {
            if (res.msg)
                throw new Error(res.msg)
            return res
        })
}