export const MAIN_BUTTONS = [
    {
        id: 1,
        text: null,
        url: '/neumaticos',
        target: '_this',
        featured: true,
        image: '/images/boton-pide-presupuesto3.png',
        image_alt: "presupuesto"
    }/* ,
    {
        id: 2,
        text: null,
        url: '/neumaticos/2055516',
        target: '_this',
        featured: false,
        image: 'https://top-wp.desarrollogrupoics.com/wp-content/uploads/2021/05/MMichelin-300x150.jpg',
        image_alt: 'Michelín'
    },
    {
        id: 3,
        text: null,
        url: '/neumaticos/1457012',
        target: '_this',
        featured: false,
        image: 'https://top-wp.desarrollogrupoics.com/wp-content/uploads/2021/05/Mfirestone.jpg',
        image_alt: 'Firestone'
    },
    {
        id: 4,
        text: null,
        url: '/neumaticos/1657012',
        target: '_this',
        featured: false,
        image: 'https://top-wp.desarrollogrupoics.com/wp-content/uploads/2021/05/MBridgestore.jpg',
        image_alt: null
    },
    {
        id: 5,
        text: null,
        url: '/neumaticos/2657017',
        target: '_this',
        featured: false,
        image: 'https://top-wp.desarrollogrupoics.com/wp-content/uploads/2021/05/MContinental.jpg',
        image_alt: null
    },
    {
        id: 6,
        text: null,
        url: '/neumaticos',
        target: '_this',
        featured: false,
        image: 'https://top-wp.desarrollogrupoics.com/wp-content/uploads/2021/05/Mgoodyear.jpg',
        image_alt: null
    },
    {
        id: 7,
        text: null,
        url: '/neumaticos',
        target: '_this',
        featured: false,
        image: 'https://top-wp.desarrollogrupoics.com/wp-content/uploads/2021/05/MDunlop.jpg',
        image_alt: null
    },
    {
        id: 8,
        text: null,
        url: '/neumaticos',
        target: '_this',
        featured: false,
        image: 'https://top-wp.desarrollogrupoics.com/wp-content/uploads/2021/05/MPirelli.jpg',
        image_alt: null
    },
    {
        id: 9,
        text: null,
        url: '/neumaticos',
        target: '_this',
        featured: false,
        image: 'https://top-wp.desarrollogrupoics.com/wp-content/uploads/2021/05/MAvon.jpg',
        image_alt: null
    },
    {
        id: 10,
        text: null,
        url: '/neumaticos',
        target: '_this',
        featured: false,
        image: 'https://top-wp.desarrollogrupoics.com/wp-content/uploads/2021/05/MKumbo.jpg',
        image_alt: null
    } */
]

export const ShopUser = {
    id: 2
}

export const sortFilters = [
    {
        name: "Precio",
        field: "precioNeto"
    },
    {
        name: "Marca",
        field: "m_nombre"
    },
    /*{
        name: "Consumo",
        field: "consumo"
    },
    {
        name: "Sonoridad",
        field: "nivel_sonoridad"
    },
    {
        name: "Adherencia",
        field: "frenada"
    }*/
];