import React from 'react'

import './GoUp.css'

export default function GoUp() {
    const handleClick = () => {
        window.scroll({
            top: 0,
            left: 0,
            behaviour: "smooth"
        })
    }
    return(
        <div className="GoUp-container" onClick={() => handleClick()}>
            <span><img src="/images/icons/prod-list/point-to-up.png" alt="Ir arriba"></img></span>
        </div>
    )
}