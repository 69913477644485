import React, {useState, useEffect, useContext} from 'react';
import { useHistory } from "react-router-dom"
import useStateWithCallback from 'use-state-with-callback'

import { Formik, Field } from 'formik';

import { Form, Row, Col1, Col10, Col9 } from 'components/ElementsStyled/Containers'
import { H1 } from 'components/ElementsStyled/H'
import { initialValues, validateFields } from 'components/TireFinder/FormikConfigs'
import { InputLabel } from 'components/ElementsStyled/InputStyle';
import ConfirmRow from './ConfirmRow';

import Context from 'context/UserContext'

import { formatFilters, arraySearchNombreKey } from 'hooks/useUtils';

import store from 'redux/index';
import { reciveTires } from 'redux/actions/tires';

import { getAnchoList, getPerfilList, getLlantaList, getCargaList, getVelocidadList, getFabricanteList, getMeasure } from 'services/tireSearch';

import './TireFinder.css'
import info from'./images/info-r.png'
import down from'./images/down.png'

/**
 * 
 * @param {*} param0 
 * @returns 
 * 
 * @TODO Cartel con las instrucciones de uso del buscador
 */

export default function TireFinder({width, profile, rim, brokerType, filters, handleSubmit }) {
    var in_array = require('in_array');
    
    const [sticky, setSticky] = useState(false)
    const {userData} = useContext(Context)

    const [infoIsOpen, setInfoIsOpen] = useState(false)

    const [medida, setMedida] = useState(undefined)
    const [ancho, setAncho] = useState(width)
    const [perfil, setPerfil] = useState(profile)
    const [llanta, setLlanta] = useState(rim)
    

    const objectFilters = formatFilters(filters)
    const [charge, setCharge] = useState((objectFilters.charge !== undefined) ? objectFilters.charge : "")
    const [velocity, setVelocity] = useState((objectFilters.velocity !== undefined) ? objectFilters.velocity : "")
    const [brand, setBrand] = useState((objectFilters.brand !== undefined) ? objectFilters.brand : "")

    const [correctMeasure, setCorrectMeasure] = useState(false)

    const filterSeasson = (objectFilters.filter_seasson !== undefined) ? objectFilters.filter_seasson : "";
    const filterRunflat = (objectFilters.filter_runflat !== undefined) ? objectFilters.filter_runflat : "";
    const filterSelfSealing = (objectFilters.filter_selfSealing !== undefined) ? objectFilters.filter_selfSealing : "";
    const filterAntiNoiseFoam = (objectFilters.filter_antiNoiseFoam !== undefined) ? objectFilters.filter_antiNoiseFoam : "";
    const filterRoad = (objectFilters.filter_road !== undefined) ? objectFilters.filter_road : "";

    /* window.onscroll = function() {
        if (window.pageYOffset > 100) {
            setSticky(true)
        } else {
            setSticky(false)
        }
    } */

    const [widthValues, setWidthValues] = useState([])
    useEffect(() => {
        getAnchoList({id_usuario: userData.id}).then(list => setWidthValues(list))
    }, [])
    const [profileValues, setProfileValues] = useState([])
    const [rimValues, setRimValues] = useState([])
    const [chargeValues, setChargeValues] = useState([])
    const [speedValues, setSpeedValues] = useState([])
    const [brandValues, setBrandValues] = useState([])

    const rechargeFilters = (carga = false, velocidad = false, fabricante = false) => {
        if (
            ancho !== undefined && ancho !== "" &&
            perfil !== undefined && perfil !== "" &&
            llanta !== undefined && llanta !== ""
        ) {
            if (carga) {
                getCargaList({id_usuario: userData.id, ancho: ancho, perfil: perfil, llanta: llanta, velocity: velocity, brand: brand}).then(list => {
                    setChargeValues(list)
                    if (list.includes(charge) === false) {
                        setCharge("")
                    }
                })
            }
            if (velocidad) {
                getVelocidadList({id_usuario: userData.id, ancho: ancho, perfil: perfil, llanta: llanta, charge: charge, brand: brand}).then(list => {
                    setSpeedValues(list)
                    if (list.includes(velocity) === false) {
                        setVelocity("")
                    }
                })
            }
            if (fabricante) {
                getFabricanteList({id_usuario: userData.id, ancho: ancho, perfil: perfil, llanta: llanta, charge: charge, velocity: velocity}).then(list => {
                    setBrandValues(list)
                    if (arraySearchNombreKey(list, brand) === undefined) {
                        setBrand("")
                    }
                })
            }
            setIv(initialValues(medida, ancho, perfil, llanta, brokerType, charge, velocity, brand, filterSeasson, filterRunflat, filterSelfSealing, filterRoad))
        } else {
            setChargeValues([])
            setSpeedValues([])
            setBrandValues([])
        }
    }

    useEffect(() => {
        if (ancho !== undefined && ancho !== "") {
            getPerfilList({id_usuario: userData.id, ancho: ancho}).then(list => setProfileValues(list))
        }
    }, [ancho])

    useEffect(() => {
        if (
            ancho !== undefined && ancho !== "" &&
            perfil !== undefined && perfil !== ""
        ) {
                getLlantaList({id_usuario: userData.id, ancho: ancho, perfil: perfil}).then(list => setRimValues(list))
            }
    }, [perfil])


    useEffect(() => {
        if (
            ancho !== undefined && ancho !== "" &&
            perfil !== undefined && perfil !== "" &&
            llanta !== undefined && llanta !== ""
        ) {
            getMeasure(ancho, perfil, llanta).then((item)  => {
                if (item === undefined) {
                    setCorrectMeasure(false)
                    setChargeValues([])
                    setSpeedValues([])
                    setBrandValues([])
                } else {
                    setMedida(item.medida)
                    setCorrectMeasure(true)
                }
            }).catch((error) => {
                setCorrectMeasure(false)
                setChargeValues([])
                setSpeedValues([])
                setBrandValues([])
            })
        } else {
            setMedida("undefined")
        }
        rechargeFilters(true, true, true)
    }, [ancho, perfil, llanta])

    useEffect(() => {
        rechargeFilters(false, true, true)
    }, [charge])

    useEffect(() => {
        rechargeFilters(true, false, true)
    }, [velocity])

    useEffect(() => {
        rechargeFilters(true, true, false)
    }, [brand])

    /* Manejar inputs de temporada */
    
    const [seasson, setSeasson] = useState(filterSeasson)

    const onSeassonClick = (buttonSeasson, setFieldValue) => {
        var s = seasson.split(",")
        if (s.length > 0 && in_array(buttonSeasson, s)) {
            s.splice(s.indexOf(buttonSeasson), 1)
        } else {
            s.push(buttonSeasson)
        }
        if (in_array("", s)) {
            s.splice(s.indexOf(""), 1)
        }
        s = (s.length > 0) ? s.join(",") : ""
        setSeasson(s)
        setFieldValue("filter_seasson", s)
    }
    /* Fin Temporada */

    /* Manejar inputs de Runflat */
    
    const [runflat, setRunflat] = useState(filterRunflat)

    const onRunflatClick = (buttonRunflat, setFieldValue) => {
        buttonRunflat = (buttonRunflat === runflat) ? "" : buttonRunflat
        setRunflat(buttonRunflat)
        setFieldValue("filter_runflat", buttonRunflat)
    }
    /* Fin Runflat */

    /* Manejar inputs de Autosellante */
    
    const [selfSealing, setSelfSealing] = useState(filterSelfSealing)

    const onSelfSealingClick = (buttonSelfSealing, setFieldValue) => {
        buttonSelfSealing = (buttonSelfSealing === selfSealing) ? "" : buttonSelfSealing
        setSelfSealing(buttonSelfSealing)
        setFieldValue("filter_selfSealing", buttonSelfSealing)
    }
    /* Fin selfSealing */

    /* Manejar inputs de Antiruido */
    
    const [antiNoiseFoam, setAntiNoiseFoam] = useState(filterAntiNoiseFoam)

    const onAntiNoiseFoamClick = (buttonAntiNoiseFoam, setFieldValue) => {
        buttonAntiNoiseFoam = (buttonAntiNoiseFoam === antiNoiseFoam) ? "" : buttonAntiNoiseFoam
        setAntiNoiseFoam(buttonAntiNoiseFoam)
        setFieldValue("filter_antiNoiseFoam", buttonAntiNoiseFoam)
    }
    /* Fin Antiruido */

    /* Manejar inputs de 4x4 */
    
    const [road, setRoad] = useState(filterRoad)

    const onRoadClick = (buttonRoad, setFieldValue) => {
        var s = road.split(",")
        if (s.length > 0 && in_array(buttonRoad, s)) {
            s.splice(s.indexOf(buttonRoad), 1)
        } else {
            s.push(buttonRoad)
        }
        if (in_array("", s)) {
            s.splice(s.indexOf(""), 1)
        }
        s = (s.length > 0) ? s.join(",") : ""
        setRoad(s)
        setFieldValue("filter_road", s)
    }
    /* Fin 4x4 */

    const handleChange = (e) => {
        setTimeout( () => {
            setCorrectMeasure(true)
            setMedida(e.target.value)
        }, 500)
    }

    const handleContinue = () => {
        store.dispatch(reciveTires(null, []))
        history.push("/presupuestos")
        setTimeout(() => {
            history.push("/presupuestos")
        }, 500)
    }

    const resetFields = (setFieldValue, fields = []) => {
        fields.forEach((field, key) => {
            setFieldValue(field.key, "")
            field.method(undefined)
        })
    }
    
    const [iv, setIv] = useState(initialValues(medida, ancho, perfil, llanta, brokerType, charge, velocity, brand, filterSeasson, filterRunflat, filterSelfSealing, filterAntiNoiseFoam, filterRoad))
    let history = useHistory()
    return (
    <div className={"TireFinder-row" + (sticky ? ' sticky' : "")}>
        <Row className={"TireFinder-container"}>
        <Col10>
            <H1 className="title">Búsqueda de neumáticos</H1>
            <div className={`Tirefinder-info ${(infoIsOpen) && 'open'}`} onClick={() => setInfoIsOpen(!infoIsOpen)}>
                <span>
                    <img src={info} alt="info" width="16" height="16" />
                    ¿Cómo buscar neumáticos?
                    <img src={down} alt="down" width="16" height="16" />
                </span>
                <br />
                <hr />
                <p>
                    Se debe de introducir la numeración sin espacios, barras, puntos, comas, etc.
                    <br /><br />
                    Ejemplos:
                </p>
                <table>
                    <thead>
                        <tr>
                            <td>Medida</td>
                            <td>Búsqueda</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>205/55R16</td>
                            <td>2055516</td>
                        </tr>
                        <tr>
                            <td>165R14</td>
                            <td>1658014</td>
                        </tr>
                        <tr>
                            <td>185/60R14</td>
                            <td>1856014</td>
                        </tr>
                        <tr>
                            <td>31x10,50R15</td>
                            <td>31105015</td>
                        </tr>
                        <tr>
                            <td>315/80R22,5</td>
                            <td>31580225</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <Formik
            initialValues={iv}
            validate={validateFields}
            onSubmit={async (values, {setSubmitting}) => {
                let filters = '';
                for (var [key, value] of Object.entries(values)) {
                    if (key !== 'measure' && key !== 'brokerType' && key !== 'width' && key !== 'profile' && key !== 'rim' && value !== '') {
                        if (filters !== '') {
                            filters = filters + '&'
                        }
                        filters = filters + key + '=' + value
                    }
                }
                if (filters !== '') {
                    filters = '/' + filters
                }
                setSubmitting(false)
                history.push(`/${values.brokerType}/${values.width}/${values.profile}/${values.rim}${filters}`)
            }}
        >
            {
                ({ isSubmitting, setFieldValue, values }) =>
                <>
                    <Row>
                        <Form>
                            <input type="hidden" name="brokerType" value={brokerType}></input>
                            <Row className="TireFinder-form-row1">

                                <Col1>
                                    <InputLabel>
                                        <span>Ancho: </span>
                                    </InputLabel>
                                </Col1>
                                <Col1>
                                    <InputLabel>
                                        <span>Perfil: </span>
                                    </InputLabel>
                                </Col1>
                                <Col1>
                                    <InputLabel>
                                        <span>Llanta: </span>
                                    </InputLabel>
                                </Col1>
                                <Col1>
                                    <InputLabel>
                                        <span>Carga: </span>
                                    </InputLabel>
                                </Col1>
                                <Col1>
                                    <InputLabel>
                                        <span>Velocidad: </span>
                                    </InputLabel>
                                </Col1>
                                <Col1>
                                    <InputLabel>
                                        <span>Fabricante: </span>
                                    </InputLabel>
                                </Col1>
                            </Row>
                            <Row className="TireFinder-form-row1">
                                <Col1>
                                    <InputLabel>
                                        <Field as="select"
                                            name="width"
                                            onChange={(e) => {
                                                setAncho(e.target.value)
                                                setFieldValue("width", e.target.value)
                                                resetFields(setFieldValue, [
                                                    {
                                                        key: "profile",
                                                        method: setPerfil
                                                    },
                                                    {
                                                        key: "rim",
                                                        method: setLlanta
                                                    },
                                                    {
                                                        key: "charge",
                                                        method: setCharge
                                                    },
                                                    {
                                                        key: "speed",
                                                        method: setVelocity
                                                    },
                                                    {
                                                        key: "brand",
                                                        method: setBrand
                                                    },
                                                ])
                                            }}
                                        >
                                            <option value="">-</option>
                                            {
                                                widthValues.map((v, key) => {
                                                return <option key={key} value={v}>{v}</option>
                                                })
                                            }
                                        </Field>
                                    </InputLabel>
                                </Col1>
                                <Col1>
                                    <InputLabel>
                                        <Field as="select"
                                            name="profile"
                                            onChange={(e) => {
                                                setPerfil(e.target.value)
                                                setFieldValue("profile", e.target.value)
                                                resetFields(setFieldValue, [
                                                    {
                                                        key: "rim",
                                                        method: setLlanta
                                                    },
                                                    {
                                                        key: "charge",
                                                        method: setCharge
                                                    },
                                                    {
                                                        key: "speed",
                                                        method: setVelocity
                                                    },
                                                    {
                                                        key: "brand",
                                                        method: setBrand
                                                    },
                                                ])
                                            }}
                                            disabled={ancho === undefined}
                                        >
                                            <option value="">-</option>
                                            {
                                                profileValues.map((v, key) => {
                                                return <option key={key} value={v}>{v}</option>
                                                })
                                            }
                                        </Field>
                                    </InputLabel>
                                </Col1>
                                <Col1>
                                    <InputLabel>
                                        <Field as="select"
                                            name="rim"
                                            onChange={(e) => {
                                                setLlanta(e.target.value)
                                                setFieldValue("rim", e.target.value)
                                                resetFields(setFieldValue, [
                                                    {
                                                        key: "charge",
                                                        method: setCharge
                                                    },
                                                    {
                                                        key: "speed",
                                                        method: setVelocity
                                                    },
                                                    {
                                                        key: "brand",
                                                        method: setBrand
                                                    },
                                                ])
                                            }}
                                            disabled={ancho === undefined || perfil === undefined}
                                        >
                                            <option value="">-</option>
                                            {
                                                rimValues.map((v, key) => {
                                                return <option key={key} value={v}>{v}</option>
                                                })
                                            }
                                        </Field>
                                    </InputLabel>
                                </Col1>
                                <Col1>
                                    <InputLabel>
                                        <Field as="select"
                                            name="charge"
                                            onChange={(e) => {
                                                setCharge(e.target.value)
                                                setFieldValue("charge", e.target.value)
                                            }}
                                            disabled={ancho === undefined || perfil === undefined || llanta === undefined}
                                        >
                                            <option value="">-</option>
                                            {
                                                chargeValues.map((v, key) => {
                                                return <option key={key} value={v}>{v}</option>
                                                })
                                            }
                                        </Field>
                                    </InputLabel>
                                </Col1>
                                <Col1>
                                    <InputLabel>
                                        <Field as="select"
                                            name="speed"
                                            onChange={(e) => {
                                                setVelocity(e.target.value)
                                                setFieldValue("speed", e.target.value)
                                            }}
                                            disabled={ancho === undefined || perfil === undefined || llanta === undefined}
                                        >
                                            <option value="">-</option>
                                            {
                                                speedValues.map((s, key) =>
                                                    <option key={key} value={s} >{s}</option>
                                                )
                                            }

                                        </Field>
                                    </InputLabel>
                                </Col1>
                                <Col1>
                                    <InputLabel>
                                        <Field as="select"
                                            name="brand"
                                            onChange={(e) => {
                                                setBrand(e.target.value)
                                                setFieldValue("brand", e.target.value)
                                            }}
                                            disabled={ancho === undefined || perfil === undefined || llanta === undefined}
                                        >
                                            <option value="">TODAS</option>
                                            {
                                                brandValues.map((m) => {
                                                        return <option key={m.id_marca} value={m.nombre} >{m.nombre}</option>
                                                    }
                                                )
                                            }

                                        </Field>
                                    </InputLabel>
                                </Col1>
                            </Row>
                            <Row className="TireFinder-form-row2">
                                <Col9>
                                    <ul className="TireFinder-filters">
                                        <li>
                                            <InputLabel>
                                                Temporada:
                                            </InputLabel>
                                                <Field type="hidden" name="filter_seasson" value={filterSeasson} />
                                                <button type="button" onClick={() => onSeassonClick('1', setFieldValue)} className={(in_array(1, seasson.split(","))) ? 'active' : ''}><img src="/images/icons/prod-list/tyre_type_100.png" alt="Verano" width="24" /></button>
                                                <button type="button" onClick={() => onSeassonClick('2', setFieldValue)} className={(in_array(2, seasson.split(","))) ? 'active' : ''}><img src="/images/icons/prod-list/tyre_type_200.png" alt="Invierno" width="24" /></button>
                                                <button type="button" onClick={() => onSeassonClick('3', setFieldValue)} className={(in_array(3, seasson.split(","))) ? 'active' : ''}><img src="/images/icons/prod-list/tyre_type_300.png" alt="4 estaciones" width="24" /></button>
                                        </li>
                                        <li>
                                            <InputLabel>
                                                Runflat:
                                            </InputLabel>
                                                <Field type="hidden" name="filter_runflat" value={filterRunflat} />
                                                <button type="button" onClick={() => onRunflatClick('1', setFieldValue)} className={(runflat === '1') ? 'active' : ''}>Sí</button>
                                                <button type="button" onClick={() => onRunflatClick('0', setFieldValue)} className={(runflat === '0') ? 'active' : ''}>No</button>
                                        </li>
                                        <li>
                                            <InputLabel>
                                                Autosellante:
                                            </InputLabel>
                                                <Field type="hidden" name="filter_selfSealing" value={filterSelfSealing} />
                                                <button type="button" onClick={() => onSelfSealingClick('1', setFieldValue)} className={(selfSealing === '1') ? 'active' : ''}>Sí</button>
                                                <button type="button" onClick={() => onSelfSealingClick('0', setFieldValue)} className={(selfSealing === '0') ? 'active' : ''}>No</button>
                                        </li>
                                        <li>
                                            <InputLabel>
                                                Espuma Antiruido:
                                            </InputLabel>
                                                <Field type="hidden" name="filter_antiNoiseFoam" value={filterAntiNoiseFoam} />
                                                <button type="button" onClick={() => onAntiNoiseFoamClick('1', setFieldValue)} className={(antiNoiseFoam === '1') ? 'active' : ''}>Sí</button>
                                                <button type="button" onClick={() => onAntiNoiseFoamClick('0', setFieldValue)} className={(antiNoiseFoam === '0') ? 'active' : ''}>No</button>
                                        </li>
                                        <li>
                                            <InputLabel>
                                                4x4:
                                            </InputLabel>
                                                <Field type="hidden" name="filter_road" value={filterRoad} />
                                                <button style={{fontSize: "10px"}} type="button" onClick={() => onRoadClick('HT', setFieldValue)} className={(in_array("HT", road.split(","))) ? 'active' : ''}>H/T<br/>(CARRETERA)</button>
                                                <button style={{fontSize: "10px"}} type="button" onClick={() => onRoadClick('AT', setFieldValue)} className={(in_array("AT", road.split(","))) ? 'active' : ''}>A/T<br/>(MIXTO)</button>
                                                <button style={{fontSize: "10px"}} type="button" onClick={() => onRoadClick('MT', setFieldValue)} className={(in_array("MT", road.split(","))) ? 'active' : ''}>M/T<br/>(OFF-ROAD)</button>
                                        </li>
                                    </ul>
                                </Col9>
                            </Row>
                            <ConfirmRow
                                isSubmitting={isSubmitting}
                                correctMeasure={correctMeasure}
                                handleContinue={handleContinue}
                                handleSubmit={handleSubmit}
                            />
                        </Form>
                    </Row>
                </>
            }
        </Formik>
        </Col10>
    </Row>
</div>
)
}